import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useAuthState } from '~/store/auth'
import { useOrderState } from '~/store/order'
import { ClientUserCreateDtoSchema, clientUserCreateDtoSchema } from '~/types/zodScheme'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useUserAccountTokenPage = () => {
    const apiClient = createApiClient()
    const navigate = useNavigate()
    const { queueDialog } = useConfirmationDialog()
    const { setToken, setMe } = useAuthState()
    const { setOrderToken } = useOrderState()

    const params = useParams()
    const token = params.token

    // initial fetch
    const { data: response } = useQuerySuspense(
        [token],
        async () => {
            if (!token) return
            const r = await apiClient.clientUserCheckMobilePhoneToken({ parameter: { token } })
            return r
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
    } = useForm<ClientUserCreateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientUserCreateDtoSchema),
    })

    useEffect(() => {
        reset({
            name: '',
            mobilePhone: response?.mobilePhone || '',
            email: response?.email || '',
            token: token || '',
            inviteCode: response?.inviteCode ?? null,
            sei: '',
            mei: '',
        })
    }, [isSubmitting])

    const sendHandler = async (dto: Schemas.ClientUserCreateDto) => {
        try {
            const token = await apiClient.clientUserCreate({ requestBody: dto })
            setToken(token)
            const me = await apiClient.clientAuthUserMe()
            setMe(me)
            await apiClient.clientGiftOrderRequestInit({})
            setOrderToken(me.uuid)
            navigate(`/user`, { replace: true })
        } catch (e) {
            let message = '登録に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }
    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        sendHandler,
    }
}

export const UserAccountTokenPage: FC = () => {
    const { register, handleSubmit, errors, isSubmitting, isValid, sendHandler } = useUserAccountTokenPage()
    return (
        <DefaultLayout title={'新規会員登録'} breadcrumbList={[]} isTab={false}>
            <Stack spacing={3}>
                <Typography variant="body2" textAlign={'center'}>
                    ニックネームはマイページからいつでも編集することができます。
                </Typography>
                <Stack spacing={0.5}>
                    <CInputLabel label="ニックネーム" required />
                    <Typography variant="caption">これ終で表示される名前・ニックネーム</Typography>
                    <TextField
                        {...register('name')}
                        placeholder={'ニックネーム'}
                        type={'text'}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        variant={'outlined'}
                    />
                </Stack>

                <LoadingButton
                    variant={'contained'}
                    disabled={!isValid}
                    loading={isSubmitting}
                    sx={{ height: '3rem' }}
                    onClick={handleSubmit(sendHandler)}>
                    この内容で登録
                </LoadingButton>
            </Stack>
        </DefaultLayout>
    )
}
