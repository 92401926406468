import { useDisclosure } from '@chakra-ui/hooks'
import { ListItem } from '@material-ui/core'
import { TabContext, TabList } from '@mui/lab'
import {
    Avatar,
    Button,
    List,
    ListItemAvatar,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Stack,
    Tab,
    Typography,
} from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import addIcon from '~/assets/image/memory/addIcon.svg'
import { CMemberDialog } from '~/components/functional/cMember/CMemberDialog'
import { CMemoryBaseInfo } from '~/components/functional/memory/cMemoryBaseInfo/CMemoryBaseInfo'
import { CMemoryFileDialog } from '~/components/functional/memory/cMemoryFileDialog/CMemoryFileDialog'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type LocationStateType = {
    assetCount: string
}
export const useMemoryMemoryUuidMemberPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const params = useParams()
    const memoryUuid = params.memoryUuid
    const navigate = useNavigate()
    const { state } = useLocation()
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
    const menuButtonHandler = (e: HTMLElement) => {
        setMenuAnchorEl(e)
    }
    const menuCloseHandler = () => {
        setMenuAnchorEl(null)
    }
    const handleMenuItem = () => {
        if (!memoryUuid) return
        navigate(`/memory/${memoryUuid}/edit`)
    }

    // initial fetch
    const { data: memory, refetch: fetchMemory } = useQuerySuspense(
        [`/memory/${memoryUuid}/member`],
        async () => {
            if (!memoryUuid) new Error()
            return await apiClient.clientMemoryGet({ parameter: { memoryUuid: memoryUuid! } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const { data: connectList, refetch: fetchConnectList } = useQuerySuspense(
        [`/memory/${memoryUuid}/connectList`],
        async () => {
            if (!memoryUuid) new Error()
            return await apiClient.clientMemoryGetConnectListByMemory({ parameter: { memoryUuid: memoryUuid ?? '' } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const { data: imageMaster } = useQuerySuspense(
        ['/memory/share/imageMaster'],
        async () => {
            return await apiClient.clientImageMasterShow({ parameter: { code: 'memoryEveryday' } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const deleteMemoryHandler = async () => {
        try {
            const message = '削除します。よろしいでしょうか？'
            if (!memoryUuid) return
            if (
                await queueDialog({
                    type: 'confirm',
                    title: '削除',
                    text: message,
                })
            ) {
                await apiClient.clientMemoryDelete({ parameter: { memoryUuid: memoryUuid } })
                navigate('/memory', { replace: true })
            }
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const tabHandler = (value: string) => {
        if (!memoryUuid) return
        navigate(`/memory/${memoryUuid}/${value}`, {
            replace: true,
            state: { assetCount: (state as LocationStateType).assetCount },
        })
    }

    const { isOpen: editDialogIsOpen, onOpen: updateModalOnOpen, onClose: editDialogOnClose } = useDisclosure()
    const addButtonHandler = () => {
        updateModalOnOpen()
        menuCloseHandler()
    }
    const editDialogSubmitHandler = async (dto: { fileUuids: string[] }) => {
        try {
            const promises = dto.fileUuids.map(
                async (i) =>
                    await apiClient.clientMemoryAssetCreate({
                        parameter: { memoryUuid: memoryUuid! },
                        requestBody: { fileUuid: i },
                    }),
            )
            await Promise.all(promises)
            // else await apiClient.clientMemoryUpdate({ parameter: { memoryUuid }, requestBody: dto })

            await fetchMemory()
            editDialogOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }
    const memoryFileDialog = (
        <CMemoryFileDialog isOpen={editDialogIsOpen} onClose={editDialogOnClose} onSubmit={editDialogSubmitHandler} />
    )

    // メンバーモーダルの表示状態
    const [connectUuids, setConnectUuids] = useState<string[]>(memory?.connects.map((c: Schemas.ConnectEntities) => c.uuid) || [])
    const { isOpen: editMemberDialogIsOpen, onOpen: updateMemberModalOnOpen, onClose: editMemberDialogOnClose } = useDisclosure()
    const memberButtonHandler = () => {
        updateMemberModalOnOpen()
    }
    const memberDialogSubmitHandler = async (uuids: string[]) => {
        if (!memoryUuid) return
        try {
            const dto: Schemas.ClientMemoryMemberUpdateDto = { connectUuids: uuids }
            await apiClient.clientMemoryUpdateMember({
                parameter: { memoryUuid: memoryUuid },
                requestBody: dto,
            })
            await fetchMemory()
            editMemberDialogOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const sendInviteHandler = async (connectUuid: string) => {
        try {
            const confirm = await queueDialog({
                type: 'confirm',
                title: '招待メール送信確認',
                text: 'つながりの招待を送信します。よろしいでしょうか？',
            })
            if (confirm) {
                await apiClient.clientMemoryCreateConnectInvite({
                    parameter: { memoryUuid: memoryUuid!, receiverMemoryConnectUuid: connectUuid },
                })
                await queueDialog({
                    type: 'alert',
                    title: '招待メール送信',
                    text: '招待メールを送信しました',
                })
            }
            await fetchConnectList()
        } catch (e) {
            let message = '送信に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const MemberDialog = (
        <CMemberDialog
            isOpen={editMemberDialogIsOpen}
            onClose={editMemberDialogOnClose}
            onSubmit={memberDialogSubmitHandler}
            setConnectUuids={setConnectUuids}
            connectList={connectList}
            uuids={connectUuids}
            title={'共有メンバーを選択'}
        />
    )

    const checkInvitable = (connect?: Schemas.ClientMemoryConnectDto | Schemas.ClientMemoryOwnerDto) => {
        if (!connect) return false
        return !connect.isCurrentUserConnect && !connect.isCurrentUser && !connect.isConnectInvited
    }

    return {
        menuAnchorEl,
        menuButtonHandler,
        menuCloseHandler,
        handleMenuItem,

        memory,
        imageMaster,
        deleteMemoryHandler,
        tabHandler,
        connectList,

        addButtonHandler,
        memoryFileDialog,
        memberButtonHandler,
        MemberDialog,
        state,
        sendInviteHandler,
        checkInvitable,
    }
}

export const MemoryMemoryUuidMemberPage: FC = () => {
    const {
        menuAnchorEl,
        menuButtonHandler,
        menuCloseHandler,
        handleMenuItem,

        memory,
        imageMaster,
        deleteMemoryHandler,
        tabHandler,
        connectList,

        addButtonHandler,
        memoryFileDialog,

        memberButtonHandler,
        MemberDialog,
        state,
        sendInviteHandler,
        checkInvitable,
    } = useMemoryMemoryUuidMemberPage()
    return (
        <>
            <DefaultLayout title="" breadcrumbList={[]} tabValue={'memory'}>
                <CMemoryBaseInfo memory={memory!} menuButtonHandler={menuButtonHandler} imageMaster={imageMaster} />
                <Stack spacing={2}>
                    <TabContext value={'member'}>
                        <TabList centered sx={{ borderBottom: '1px solid #EFEAE6' }}>
                            <Tab
                                label={
                                    <Typography variant={'body1'}>{`メディア (${
                                        (state as LocationStateType).assetCount
                                    })`}</Typography>
                                }
                                value={''}
                                onClick={() => tabHandler('')}
                            />
                            <Tab
                                label={<Typography variant={'body1'}>{`コメント (${memory?.commentCount})`}</Typography>}
                                value={'comment'}
                                onClick={() => tabHandler('comment')}
                            />
                            <Tab
                                label={<Typography variant={'body1'}>{`メンバー (${memory!.connects.length + 1})`}</Typography>}
                                value={'member'}
                                onClick={() => tabHandler('member')}
                            />
                        </TabList>
                    </TabContext>
                    <Stack direction={'column'} spacing={1}>
                        <Button variant={'contained'} startIcon={<img src={addIcon} />} onClick={() => memberButtonHandler()}>
                            共有メンバー編集
                        </Button>
                        <Typography variant={'h6'}>{`${memory!.connects.length + 1}人のメンバー`}</Typography>
                        <List>
                            <ListItem divider>
                                <ListItemAvatar>
                                    <Avatar
                                        alt={connectList?.memoryOwner.name}
                                        src={mediaUrl(connectList?.memoryOwner.file, true)}
                                        sx={{ width: 40, height: 40 }}
                                    />
                                </ListItemAvatar>
                                <ListItemText primary={connectList?.memoryOwner.name} />
                                {checkInvitable(connectList?.memoryOwner) && (
                                    <Button sx={{ p: 0 }} startIcon={<img src={addIcon} alt="招待する" />} onClick={() => {}} />
                                )}
                            </ListItem>
                            {connectList?.connects &&
                                connectList.connects.map((connect) => {
                                    return (
                                        memory?.connects.find((i) => connect.uuid === i.uuid) && (
                                            <ListItem key={connect.uuid} divider>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        alt={connect!.name}
                                                        src={mediaUrl(connect!.file, true)}
                                                        sx={{ width: 40, height: 40 }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText primary={connect.name} />
                                                {checkInvitable(connect) && (
                                                    <Button
                                                        sx={{ p: 0 }}
                                                        startIcon={<img src={addIcon} alt="招待する" />}
                                                        onClick={() => sendInviteHandler(connect.uuid)}
                                                    />
                                                )}
                                            </ListItem>
                                        )
                                    )
                                })}
                        </List>
                    </Stack>
                </Stack>
            </DefaultLayout>
            {memoryFileDialog}
            {MemberDialog}
            <Menu
                open={!!menuAnchorEl}
                onClose={menuCloseHandler}
                anchorEl={menuAnchorEl}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        boxShadow: 1,
                        px: 1,
                        placement: 'bottom-end',
                    },
                }}>
                <Typography variant={'body2'} textAlign={'center'} sx={{ borderBottom: 1, pb: 1 }}>
                    操作を選択してください
                </Typography>
                <MenuList dense>
                    <MenuItem divider sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => addButtonHandler()}>
                        <Button variant={'text'} color={'primary'}>
                            画像を追加
                        </Button>
                    </MenuItem>
                    <MenuItem divider sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => handleMenuItem()}>
                        <Button variant={'text'} color={'primary'}>
                            思い出を編集
                        </Button>
                    </MenuItem>
                    <MenuItem sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => deleteMemoryHandler()}>
                        <Button variant={'text'} color={'error'}>
                            思い出を削除
                        </Button>
                    </MenuItem>
                </MenuList>
            </Menu>
        </>
    )
}
