import { zodResolver } from '@hookform/resolvers/zod'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, FormControlLabel, Radio, Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { type FC, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router'

import { Schemas } from '~/apis/types'
import horizontalGrave from '~/assets/image/grave/ohaka_boseki_yougata_black.png'
import verticalGrave from '~/assets/image/grave/osoushiki_ohaka_white.png'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { ClientRelativeGraveCreateDtoSchema, clientRelativeGraveCreateDtoSchema } from '~/types/zodScheme'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type GraveType = 'horizontal' | 'vertical'

const useRelativeGraveRegisterPage = () => {
    const location = useLocation()

    const { queueDialog } = useConfirmationDialog()
    const onErrorHandle = async (e: unknown) => {
        let message = '取得に失敗しました'
        if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
        await queueDialog({
            type: 'alert',
            title: 'エラーが発生しました',
            text: message,
        })
    }
    const apiClient = createApiClient()

    const { data: imageMasters } = useQuerySuspense(
        [`/grave/master`],
        async () => {
            return await apiClient.clientImageMasterIndex()
        },
        {
            onError: onErrorHandle,
        },
    )

    const getMasterToIMage = (): Schemas.FileEntities | undefined => {
        if (imageMasters) {
            if (graveType === 'horizontal') {
                const yoko = imageMasters.find((master: Schemas.ImageMasterEntities) => master.code === 'graveYoko')
                return yoko?.file
            } else {
                const tate = imageMasters.find((master: Schemas.ImageMasterEntities) => master.code === 'graveTate')
                return tate?.file
            }
        }
        return undefined
    }

    const navigate = useNavigate()

    const [graveType, setGraveType] = useState<GraveType>('horizontal')

    const {
        register,
        getValues,
        setValue,
        formState: { isValid, isSubmitting, errors },
    } = useForm<ClientRelativeGraveCreateDtoSchema>({
        resolver: zodResolver(clientRelativeGraveCreateDtoSchema),
        mode: 'all',
        defaultValues: { graveFile: '' },
    })

    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null)
    const [context, setContext] = useState<CanvasRenderingContext2D | null>(null)

    const getContext = () => {
        const tempCanvas = canvasRef.current as HTMLCanvasElement | null
        if (!tempCanvas) return
        setCanvas(tempCanvas)
        const tempContext = tempCanvas.getContext('2d')
        if (!tempContext) return
        tempContext.imageSmoothingEnabled = false
        setContext(tempContext)
    }

    const handleChangeCanvas = (options?: { posthumousName?: string; denomination?: string }) => {
        if (!context) getContext()
        let posthumousName = getValues('posthumousName') || ''
        if (typeof options !== 'undefined' && typeof options.posthumousName !== 'undefined')
            posthumousName = options.posthumousName
        let denomination = getValues('denomination') || ''
        if (typeof options !== 'undefined' && typeof options.denomination !== 'undefined') denomination = options.denomination

        if (context !== null) {
            const img = new Image()
            img.src = mediaUrl(getMasterToIMage())
            img.crossOrigin = 'anonymous'
            img.onload = () => {
                context.beginPath()
                context.fillStyle = '#fafafa'
                context.fillRect(0, 0, canvas!.width, canvas!.height)
                context.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas!.width, canvas!.height)

                if (!posthumousName && !denomination) return

                const centerWidthPoint = 327 / 2
                if (graveType === 'horizontal') {
                    // 横書き
                    if (posthumousName && !denomination) {
                        context.textAlign = 'center'
                        context.fillStyle = '#ffffff'
                        context.font = 'bold 18px serif'
                        context.fillText(posthumousName || '', centerWidthPoint, 163 + 18)
                    } else {
                        context.fillStyle = '#ffffff'
                        context.textAlign = 'center'
                        context.font = 'bold 18px serif'
                        context.fillText(posthumousName || '', centerWidthPoint, 145 + 18)
                        context.font = `bold 12px serif`
                        context.fillText(denomination || '', centerWidthPoint, 182 + 12)
                    }
                } else {
                    // 縦書き
                    context.textAlign = 'left'
                    context.fillStyle = '#333333'
                    const mainTitleFontSize = 18
                    const subTitleFontSize = 12
                    const subTitleFontSizeOver15 = 11
                    if (posthumousName && !denomination) {
                        context.font = `bold ${mainTitleFontSize}px serif`
                        posthumousName.split('').map((char, index) => {
                            context.fillText(
                                char,
                                centerWidthPoint - mainTitleFontSize / 2,
                                index * mainTitleFontSize * 1.2 + mainTitleFontSize + 57,
                            )
                        })
                    } else {
                        context.font = `bold ${mainTitleFontSize}px serif`
                        posthumousName.split('').map((char, index) => {
                            context.fillText(char, 163, index * mainTitleFontSize * 1.1 + mainTitleFontSize + 57)
                        })

                        if (denomination.length <= 18) {
                            context.font = `bold ${subTitleFontSize}px serif`
                            denomination.split('').map((char, index) => {
                                context.fillText(char, 140, index * subTitleFontSize * 1.1 + subTitleFontSize + 62)
                            })
                        } else {
                            context.font = `bold ${subTitleFontSizeOver15}px serif`
                            denomination.split('').map((char, index) => {
                                context.fillText(char, 140, index * subTitleFontSizeOver15 * 1.1 + subTitleFontSizeOver15 + 62)
                            })
                        }
                    }
                }
            }
        }
    }

    // canvas 保存
    const handlerSaveCanvas = async () => {
        if (!canvas) return
        return canvas.toBlob(
            async (blob) => {
                if (blob) {
                    const formData = new FormData()
                    formData.append('files', blob)
                    const uploadedFiles = await apiClient
                        // @ts-expect-error
                        .clientFileUploadUpload({ requestBody: formData })
                    setValue('graveFile', uploadedFiles[0].uuid, { shouldValidate: true })
                    return await apiClient
                        .clientConnectCreateRelativeUserAndConnect({
                            requestBody: {
                                ...getValues(),
                                ...(location.state as Schemas.ClientRelativeUserCreateDto),
                                file: (location.state as Schemas.ClientRelativeUserCreateDto).fileUuid,
                                fileUuid: getValues('graveFile') ?? '',
                                vertical: graveType === 'vertical',
                            },
                        })
                        .then((res) => {
                            navigate(`/connectList/${res.uuid}/relative`, {
                                replace: true,
                            })
                        })
                        .catch(onErrorHandle)
                }
            },
            'image/png',
            1,
        )
    }

    useEffect(() => {
        if (imageMasters) handleChangeCanvas()
    }, [context, imageMasters, graveType])

    return {
        handleChangeCanvas,
        handlerSaveCanvas,
        errors,
        setGraveType,
        graveType,
        register,
        isValid,
        isSubmitting,
        canvasRef,
        setValue,
    }
}
export const RelativeGraveRegisterPageView = () => {
    const {
        handleChangeCanvas,
        handlerSaveCanvas,
        errors,
        setGraveType,
        graveType,
        register,
        isSubmitting,
        isValid,
        canvasRef,
        setValue,
    } = useRelativeGraveRegisterPage()
    return (
        <DefaultLayout title={'お墓を作成'} breadcrumbList={[]}>
            <Stack spacing={4} alignItems={'center'}>
                <Typography fontSize={'large'} textAlign={'center'}>
                    既に亡くなってしまった方のお墓を代理で作成できます。お好みの墓石を選択してください。
                </Typography>

                <Stack direction={'row'} spacing={1}>
                    <Stack alignItems={'center'}>
                        <Box
                            {...(graveType === 'horizontal' && { border: '2px solid #AF985A' })}
                            bgcolor={'white'}
                            width={'146px'}
                            height={'146px'}
                            borderRadius={'10px'}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}>
                            <img alt="" src={horizontalGrave} style={{ objectFit: 'contain', width: '80%', height: '80%' }} />
                        </Box>
                        <FormControlLabel
                            value={graveType}
                            control={
                                <Radio
                                    checked={graveType === 'horizontal'}
                                    onChange={() => {
                                        setGraveType('horizontal')
                                    }}
                                />
                            }
                            label={'横書き'}
                        />
                    </Stack>
                    <Stack alignItems={'center'}>
                        <Box
                            {...(graveType === 'vertical' && { border: '2px solid #AF985A' })}
                            bgcolor={'white'}
                            width={'146px'}
                            height={'146px'}
                            borderRadius={'10px'}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}>
                            <img alt="" src={verticalGrave} style={{ objectFit: 'contain', width: '80%', height: '80%' }} />
                        </Box>
                        <FormControlLabel
                            control={
                                <Radio
                                    checked={graveType === 'vertical'}
                                    onChange={() => {
                                        setGraveType('vertical')
                                    }}
                                />
                            }
                            label={'縦書き'}
                        />
                    </Stack>
                </Stack>
                <Stack spacing={1} width={'100%'}>
                    <Stack spacing={0.5}>
                        <CInputLabel label={'氏名'} required />
                        <Typography color={'#5E564A'} variant={'body1'}>
                            全角10文字以内
                        </Typography>
                        <TextField
                            {...register('posthumousName')}
                            id={'posthumousName'}
                            required={true}
                            placeholder={'山田 太郎'}
                            variant={'outlined'}
                            onChange={(e) => {
                                handleChangeCanvas({ posthumousName: e.target.value })
                                setValue('posthumousName', e.target.value, { shouldValidate: true })
                            }}
                            error={!!errors.posthumousName}
                            helperText={errors.posthumousName?.message}
                        />
                        <Typography color={'#5E564A'} variant={'body1'}>
                            ※ 絵文字や特殊文字は使えません
                        </Typography>
                    </Stack>
                    <Stack spacing={0.5}>
                        <CInputLabel label={'没年月日・享年など'} />
                        <Typography color={'#5E564A'} variant={'body1'}>
                            全角20文字以内
                        </Typography>
                        <TextField
                            {...register('denomination')}
                            id={'denomination'}
                            required={true}
                            placeholder={'令和五年十一月二十五日享年八十才'}
                            variant={'outlined'}
                            onChange={(e) => {
                                handleChangeCanvas({ denomination: e.target.value })
                                setValue('denomination', e.target.value, { shouldValidate: true })
                            }}
                            error={!!errors.denomination}
                            helperText={errors.denomination?.message}
                        />
                        <Typography color={'#5E564A'} variant={'body1'}>
                            ※ 絵文字や特殊文字は使えません
                        </Typography>
                    </Stack>
                </Stack>
                <Box sx={{ display: 'none', justifyContent: 'center' }}>
                    <canvas width={'327px'} height={'400px'} ref={canvasRef} />
                </Box>

                <LoadingButton
                    onClick={handlerSaveCanvas}
                    sx={{ mt: '2rem', height: '3rem' }}
                    variant={'contained'}
                    fullWidth
                    disabled={!isValid}
                    loading={isSubmitting}>
                    お墓を作成する
                </LoadingButton>
            </Stack>
        </DefaultLayout>
    )
}

export const RelativeGraveRegisterPage: FC = () => {
    return <RelativeGraveRegisterPageView />
}

export default RelativeGraveRegisterPage
