import { Avatar, Box, Button, Checkbox, FormControlLabel, Stack, SvgIcon, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'
// 選択状態を管理するカスタムフック
const useSetSelection = <T,>(initialValues: T[]) => {
    const [selectedItems, setSelectedItems] = useState<Set<T>>(new Set(initialValues))

    // アイテムの選択をトグルする関数
    const toggleItem = (item: T) => {
        setSelectedItems((prevSelected) => {
            const newSelected = new Set(prevSelected)
            if (newSelected.has(item)) newSelected.delete(item)
            else newSelected.add(item)

            return newSelected
        })
    }

    const selectedArray = Array.from(selectedItems)

    // 選択されたアイテムをすべて削除する関数
    const removeAll = () => {
        setSelectedItems(new Set([]))
    }

    return [selectedArray, toggleItem, removeAll] as const
}

export const useRelativeAdminPage = () => {
    const apiClient = createApiClient()
    const { connectUuid } = useParams()

    const { queueDialog } = useConfirmationDialog()

    const navigate = useNavigate()

    const onErrorHandle = async (e: unknown) => {
        let message = '取得に失敗しました'
        if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
        await queueDialog({
            type: 'alert',
            title: 'エラーが発生しました',
            text: message,
        })
    }
    const { data: admins, refetch } = useQuerySuspense(
        [connectUuid + 'adminList'],
        async () => {
            return await apiClient.clientConnectGetRelativeConnectList({ parameter: { connectUuid: connectUuid ?? '' } })
        },
        { onError: onErrorHandle },
    )

    const initializeAdminState = () => {
        if (!admins?.list || !admins?.list?.length) return []
        const adminsList = admins?.list?.filter((i) => i.afterPermit === 1)
        return adminsList.map((i) => i.uuid)
    }

    const [selectedAdmins, setSelectedAdmins] = useSetSelection<string>(initializeAdminState())

    const adminRows = useMemo(() => {
        return admins?.list?.map((i) => {
            return (
                <Box key={i.uuid} borderTop={'0.5px #C6C6C8 solid'} mx={'1rem'}>
                    <AdminProfileRow
                        connect={i}
                        checked={selectedAdmins.includes(i.uuid)}
                        setChecked={() => setSelectedAdmins(i.uuid)}
                    />
                </Box>
            )
        })
    }, [selectedAdmins])

    const onSubmit = async () => {
        if (
            await queueDialog({
                type: 'confirm',
                title: '',
                text: '管理者が変更よろしいですか？',
            })
        ) {
            return await apiClient
                .clientConnectUpdateRelativeAdminPermit({
                    parameter: { connectUuid: connectUuid ?? '', connectUuids: selectedAdmins },
                })
                .then(async () => {
                    await refetch()
                    navigate(`/connectList/${connectUuid}/relative`)
                })
                .catch(onErrorHandle)
        }
    }
    return { admins, adminRows, onSubmit, navigate, connectUuid, selectedAdmins }
}
export const RelativeAdminPageView: FC<ReturnType<typeof useRelativeAdminPage>> = () => {
    const { admins, adminRows, onSubmit, navigate, connectUuid, selectedAdmins } = useRelativeAdminPage()
    return (
        <DefaultLayout breadcrumbList={[]}>
            <Stack spacing={3}>
                <Stack alignItems={'center'} spacing={1}>
                    <Typography variant={'subtitle2'}>お墓の管理依頼</Typography>
                    <Typography variant={'body1'}>このユーザーのお墓の管理を依頼したいユーザーを選択してください。</Typography>
                </Stack>
                {admins?.list && admins?.list.length ? (
                    <>
                        <Stack bgcolor={'white'} borderRadius={'1rem'} border={'1px solid #AF985A'}>
                            {adminRows}
                        </Stack>
                        <Stack spacing={1} direction={'row'} justifyContent={'center'}>
                            <Button
                                onClick={() => navigate(`/connectList/${connectUuid}/relative`)}
                                variant={'outlined'}
                                sx={{ maxWidth: '40%' }}>
                                キャンセル
                            </Button>
                            <Button
                                disabled={!selectedAdmins.length}
                                onClick={onSubmit}
                                variant={'contained'}
                                fullWidth
                                sx={{ maxWidth: '60%' }}>
                                保存
                            </Button>
                        </Stack>
                    </>
                ) : (
                    <Typography variant={'body1'} sx={{ textAlign: 'center' }}>
                        この身内・友人はどのユーザーともつながっていません
                    </Typography>
                )}
            </Stack>
        </DefaultLayout>
    )
}

export const RelativeAdminPage = () => {
    const hookItems = useRelativeAdminPage()
    return <RelativeAdminPageView {...hookItems} />
}

const AdminProfileRow: FC<{ connect: Schemas.ConnectEntities; checked: boolean; setChecked: () => void }> = ({
    connect,
    checked,
    setChecked,
}) => {
    return (
        <Stack py={1}>
            <FormControlLabel
                key={connect.uuid}
                checked={checked}
                onChange={setChecked}
                label={
                    <Stack spacing={1} direction={'row'} alignItems={'center'}>
                        <Avatar src={mediaUrl(connect.file)} />
                        <Typography>{connect.name}</Typography>
                    </Stack>
                }
                sx={{
                    '.MuiFormControlLabel-label': { fontSize: '18px' },
                }}
                control={
                    <Checkbox
                        icon={
                            <SvgIcon>
                                <svg focusable="false" aria-hidden="true" data-testid="CheckBoxBlankIcon">
                                    <path
                                        d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5"
                                        fill="white"
                                        stroke="#B188CA"
                                        strokeWidth={'1px'}
                                        strokeLinejoin="round"
                                        paintOrder={'stroke'}
                                    />
                                </svg>
                            </SvgIcon>
                        }
                    />
                }
            />
        </Stack>
    )
}
