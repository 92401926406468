import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Card, CardMedia, Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'

import { Schemas } from '~/apis/types'
import noImage from '~/assets/image/noImage.png'
import { CFileUpload } from '~/components/common/cFileUpload/CFileUpload'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { ClientGraveCreateDtoSchema, clientGraveCreateDtoSchema } from '~/types/zodScheme'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

const usePage = () => {
    const apiClient = createApiClient()
    const navigate = useNavigate()
    const { queueDialog } = useConfirmationDialog()

    // initial fetch
    const { data: grave } = useQuerySuspense(
        [`/grave`],
        async () => {
            return await apiClient.clientGraveGet({})
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const {
        formState: { isSubmitting, errors },
        reset,
        setValue,
        getValues,
    } = useForm<ClientGraveCreateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientGraveCreateDtoSchema),
    })

    const [image, setImage] = useState<Schemas.FileEntities | undefined>(grave?.file || undefined)

    // 初期化
    useEffect(() => {
        reset({
            name: grave?.name || '',
            posthumousName: grave?.posthumousName || '',
            denomination: grave?.denomination || '',
            fileUuid: grave?.file?.uuid || undefined,
            vertical: grave?.vertical || false,
        })
        setImage(grave?.file || undefined)
    }, [grave])

    const handleUploadFile = (value?: Schemas.FileEntities[], error?: unknown) => {
        console.log(value, error)
        if (value?.[0].uuid) {
            setValue('fileUuid', value?.[0].uuid)
            setImage(value[0])
        }
    }

    // 編集モーダルの表示状態
    const editDialogSubmitHandler = async (dto: Schemas.ClientGraveCreateDto) => {
        try {
            await apiClient.clientGraveUpdate({ requestBody: dto })
            navigate('/grave')
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    // 保存
    const handlerSave = async () => {
        await editDialogSubmitHandler({
            fileUuid: getValues('fileUuid')!,
            name: getValues('name'),
            posthumousName: getValues('posthumousName'),
            denomination: getValues('denomination'),
            vertical: getValues('vertical'),
        })
    }

    return {
        navigate,
        isSubmitting,
        handlerSave,
        handleUploadFile,
        errors,
        image,
    }
}

export const GraveUploadPage: FC = () => {
    const { navigate, handleUploadFile, image, errors, isSubmitting, handlerSave } = usePage()
    return (
        <>
            <DefaultLayout title={'お墓の写真をアップロード'} breadcrumbList={[]}>
                <Stack spacing={2}>
                    <Typography variant={'body2'} textAlign={'center'}>
                        実際のお墓の写真を設定することができます。
                    </Typography>
                    <Stack spacing={0.5}>
                        <CFileUpload
                            completionHandler={handleUploadFile}
                            label={''}
                            error={!!errors.fileUuid}
                            helperText={errors.fileUuid?.message}
                        />
                    </Stack>

                    <Stack spacing={2}>
                        {image?.path ? (
                            <Card sx={{ width: 327, marginLeft: 'auto', marginRight: 'auto' }}>
                                <CardMedia component="img" height="400" width="327" image={mediaUrl(image)} alt="" />
                            </Card>
                        ) : (
                            <Box>
                                <Box
                                    component="img"
                                    sx={{
                                        display: 'block',
                                        overflow: 'hidden',
                                        width: '327px',
                                        height: '400px',
                                        objectFit: 'cover',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                    }}
                                    src={noImage}
                                />
                            </Box>
                        )}

                        <Typography variant="caption" sx={{ px: 2 }}>
                            アップロードされた画像は、幅327px 横400pxで切り抜かれて表示されます
                        </Typography>
                    </Stack>

                    <Stack spacing={2} direction="row" justifyContent={'center'} sx={{ mt: 2 }}>
                        <Button variant="outlined" fullWidth onClick={() => navigate('/grave')}>
                            キャンセル
                        </Button>
                        <LoadingButton variant={'contained'} fullWidth loading={isSubmitting} onClick={handlerSave}>
                            この画像で保存
                        </LoadingButton>
                    </Stack>
                </Stack>
            </DefaultLayout>
        </>
    )
}
