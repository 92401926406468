import { Box } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, MouseEvent, useEffect, useRef, useState } from 'react'

import { Schemas } from '~/apis/types'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { asyncMap } from '~/utils/array-util'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

const useCGraveOfferingSpecifyPosition = (
    connectUuid: string,
    details: Schemas.ClientOfferingOrderPreviewDto[],
    master?: Schemas.OfferingMasterEntities,
    completeHandler?: (x: number, y: number) => void,
) => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()

    /**
     * お墓を取得する
     */
    const { data: grave } = useQuerySuspense(
        [`/grave/offering/detail/grave`],
        async () => {
            return await apiClient.clientConnectGraveGetConnectGrave({ parameter: { connectUuid: connectUuid! } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null)
    const [context, setContext] = useState<CanvasRenderingContext2D | null>(null)
    const [positionX, setPositionX] = useState(0)
    const [positionY, setPositionY] = useState(0)

    /**
     * canvas, context 取得
     */
    const getContext = () => {
        const tempCanvas = canvasRef.current as HTMLCanvasElement | null
        if (!tempCanvas) return
        setCanvas(tempCanvas)
        const tempContext = tempCanvas.getContext('2d')
        if (!tempContext) return
        setContext(tempContext)
    }

    /**
     * canvas に書き込む
     */
    const handleChangeCanvas = () => {
        if (!context) getContext()
        if (!grave?.file) return

        if (context !== null) {
            // 背景を塗りつぶす
            context.beginPath()
            context.fillStyle = '#F9F5F2'
            context.fillRect(0, 0, canvas!.width, canvas!.height)
            // 現在のお墓の画像を描画する
            const img = new Image()
            img.src = mediaUrl(grave.file)
            img.onload = async () => {
                // 頭4つは画像のどこを使うかなので固定, 5, 6 は 位置, 7, 8 は 画像の大きさ
                const [sx, sy, sw, sh] = calcGraveDrawSize(img)
                context.drawImage(img, sx, sy, sw, sh, 0, 0, canvas!.width, canvas!.height)
                // カートの内容を描画する
                await showCartImage()
                // 今回のお供え物を描画する
                await showMasterImage()
            }
        }
    }

    const calcGraveDrawSize = (img: HTMLImageElement) => {
        if (img.width === 327 && img.height === 400) return [0, 0, img.width, img.height]
        let sx: number
        let sy: number
        let sw: number
        let sh: number

        if (img.width >= img.height) {
            // 横のほうが大きい場合
            sy = 0
            sh = img.height
            const width = img.height * (327 / 400)
            sx = (img.width - width) / 2
            sw = width
        } else {
            // 縦のほうが大き場合
            sx = 0
            sw = img.width
            const height = img.width * (400 / 327)
            sy = (img.height - height) / 2
            sh = height
        }

        return [sx, sy, sw, sh]
    }

    /**
     * canvas image読み込み用Promise
     * @param src
     */
    const loadImage = (src: string): Promise<HTMLImageElement> => {
        return new Promise((resolve, reject) => {
            const img = new Image()
            img.onload = () => resolve(img)
            img.onerror = (e) => reject(e)
            img.src = src
        })
    }

    /**
     * カートの内容を描画する
     */
    const showCartImage = async () => {
        await asyncMap(details, async (item: Schemas.ClientOfferingOrderPreviewDto) => {
            const img = await loadImage(mediaUrl(item.offeringMaster.file)).catch((e) => {
                console.error('画像の読み込みに失敗しました', e)
                return undefined
            })
            if (!img) return
            const [width, height] = calcWidthHeight(img)
            context!.drawImage(img, 0, 0, img.width, img.height, item.positionX || 0, item.positionY || 0, width, height)
        })
    }

    /**
     * 画像の大きさから描画する width, height を決定する
     * @param img
     */
    const calcWidthHeight = (img: HTMLImageElement): [number, number] => {
        let width = img.width
        let height = img.height
        if (img.width > img.height) {
            // 横のほうが大きい場合
            width = 400 / 5
            const rate = width / img.width
            height = height * rate
        } else {
            // 縦のほうが大き場合
            height = 400 / 5
            const rate = height / img.height
            width = width * rate
        }
        return [width, height]
    }

    /**
     * 現在選択されているお供え物を描画する
     */
    const showMasterImage = async () => {
        if (!master) return
        if (!context) return
        if (positionX === 0 && positionY === 0) return
        const img = await loadImage(mediaUrl(master.file)).catch((e) => {
            console.error('画像の読み込みに失敗しました', e)
            return undefined
        })
        if (!img) return
        const [width, height] = calcWidthHeight(img)
        // 画像の真ん中を基準点として描画する
        const dx = Math.floor(positionX - width / 2)
        const dy = Math.floor(positionY - height / 2)

        context.beginPath()
        context.moveTo(dx + 1, dy + 1)
        context.lineTo(dx + width + 1, dy + 1)
        context.lineTo(dx + width + 1, dy + height + 1)
        context.lineTo(dx + 1, dy + height + 1)
        context.lineTo(dx + 1, dy + 1)
        context.lineWidth = 1
        context.strokeStyle = '#666666'
        context.stroke()

        context.beginPath()
        context.moveTo(dx, dy)
        context.lineTo(dx + width, dy)
        context.lineTo(dx + width, dy + height)
        context.lineTo(dx, dy + height)
        context.lineTo(dx, dy)
        context.lineWidth = 1
        context.strokeStyle = '#FFFFFF'
        context.stroke()

        // 頭4つは画像のどこを使うかなので固定, 5, 6 は 位置, 7, 8 は 画像の大きさ
        context.drawImage(img, 0, 0, img.width, img.height, dx, dy, width, height)

        if (completeHandler) completeHandler(dx, dy)
    }

    /**
     * position X, Y が変更されたら書き込み直す
     */
    useEffect(() => {
        handleChangeCanvas()
    }, [context, positionX, positionY, details, master, grave])

    /**
     * canvas がクリックされたら位置を変更し useEffect で再描画する
     * @param event
     */
    const handleMouseDownCanvas = (event: MouseEvent) => {
        const rect = canvas!.getBoundingClientRect()
        const x = event.clientX - rect.left
        const y = event.clientY - rect.top
        setPositionX(x)
        setPositionY(y)
    }

    return { canvasRef, handleMouseDownCanvas }
}

type CGraveOfferingSpecifyPositionProps = {
    connectUuid: string
    master?: Schemas.OfferingMasterEntities
    completeHandler?: (x: number, y: number) => void
    details: Schemas.ClientOfferingOrderPreviewDto[]
}

export const CGraveOfferingSpecifyPosition: FC<CGraveOfferingSpecifyPositionProps> = ({
    connectUuid,
    master,
    completeHandler,
    details,
}) => {
    const { canvasRef, handleMouseDownCanvas } = useCGraveOfferingSpecifyPosition(connectUuid, details, master, completeHandler)
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <canvas
                    ref={canvasRef}
                    width="327"
                    height="400"
                    style={{
                        width: '327px',
                        height: '400px',
                        border: `1px solid #cccccc`,
                        backgroundColor: '#eee',
                    }}
                    onMouseDown={(e) => {
                        handleMouseDownCanvas(e)
                    }}
                />
            </Box>
        </>
    )
}
