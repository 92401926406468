import { Flex, Spinner } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useAuthState } from '~/store/auth'
import { useOrderState } from '~/store/order'
import { ClientUserMailNicknameUpdateDtoSchema, clientUserMailNicknameUpdateDtoSchema } from '~/types/zodScheme'
import { createApiClient } from '~/utils/createApiClient'

export const useLoginStatusPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const params = useParams()
    const { setToken, setMe, clearAuthData } = useAuthState()
    const [searchParams] = useSearchParams()
    const token = searchParams.get('token')
    const type = searchParams.get('type')
    const expire = searchParams.get('expire')
    const email = searchParams.get('email')
    const name = searchParams.get('name')
    const isSignup = searchParams.get('isSignup') === 'true'
    const isExistingUser = searchParams.get('isExistingUser') === 'true'
    const { setOrderToken } = useOrderState()
    const navigate = useNavigate()

    const loginHandler = async (accessToken: string, exp: string) => {
        if (params.status !== 'success') throw new Error('ログインに失敗しました')
        const res: Schemas.ClientLoginResultDto = {
            accessToken: accessToken ?? token,
            expire: exp ?? expire,
        }

        setToken(res)
        const me = await apiClient.clientAuthUserMe()
        setMe(me)
        await apiClient.clientGiftOrderRequestInit({})
        setOrderToken(me.uuid)
    }

    const [mergeAccount, setMergeAccount] = useState(isExistingUser)

    useEffect(() => {
        const onPageLoad = async () => {
            try {
                if (params.status !== 'success') throw new Error('ログインに失敗しました')
                if (token && expire) await loginHandler(token, expire)
                if (!isSignup) navigate('/user')
            } catch (e) {
                let message = 'ログインに失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
                navigate('/login')
            }
        }
        onPageLoad()
    }, [])

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
        watch,
    } = useForm<ClientUserMailNicknameUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientUserMailNicknameUpdateDtoSchema),
    })

    const emailValue = watch('email')

    const sendHandler = async (dto: ClientUserMailNicknameUpdateDtoSchema) => {
        try {
            const snsType = type === '1' ? 1 : 0
            const snsRegister = await apiClient.clientAuthSnsRegister({
                requestBody: { email: dto.email, nickName: dto.nickname, type: snsType },
                parameter: { token: token || '' },
            })
            if (snsRegister) {
                setToken(snsRegister)
                await loginHandler(snsRegister.accessToken, snsRegister.expire)
            }
            if (emailValue) {
                await apiClient.clientUserUpdateMail({ requestBody: { email: dto.email || '' } })
                await queueDialog({
                    type: 'alert',
                    title: 'メールを送信',
                    text: '入力されたメールアドレスへメールを送信しました',
                })
                navigate('/user/mail', { state: { email: dto.email }, replace: true })
            } else navigate('/user', { replace: true })
        } catch (e) {
            let message = '登録に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const onClickMergeAccount = async () => {
        await apiClient.clientUserCreateEmailPassword({ requestBody: { email: email || '' } })
        setMergeAccount(true)
        await queueDialog({
            type: 'alert',
            title: 'メールを送信',
            text: '入力されたメールアドレスへOTPを送信しました',
        })
        navigate(`/login`, { state: { email, token }, replace: true })
        clearAuthData()
    }

    const onClickCreateAccount = async () => {
        const confirm = await queueDialog({
            type: 'confirm',
            title: '新しいアカウント作成',
            text: `新しいアカウントを作成します。よろしいですか？\n※ 後でアカウント統合を行うことはできません。`,
        })
        if (confirm) {
            setMergeAccount(false)
            navigate(`/login/success?token=${token}&isSignup=true&name=${name}&type=1`, {
                replace: true,
            })
            clearAuthData()
        }
    }

    useEffect(() => {
        reset({
            nickname: name ?? '',
            email: email ?? '',
        })
    }, [email, name])

    return {
        isSignup,
        register,
        errors,
        isSubmitting,
        isValid,
        mergeAccount,
        email,
        onClickMergeAccount,
        onClickCreateAccount,
        sendHandler,
        handleSubmit,
    }
}

export const LoginStatusPage: FC = () => {
    const {
        isSignup,
        register,
        errors,
        isSubmitting,
        isValid,
        mergeAccount,
        email,
        onClickMergeAccount,
        onClickCreateAccount,
        sendHandler,
        handleSubmit,
    } = useLoginStatusPage()
    return (
        <>
            <DefaultLayout title={isSignup ? '新規会員登録' : 'ログイン中です。。'} breadcrumbList={[]} isTab={false}>
                {!isSignup && <Spinner />}
                {isSignup && !mergeAccount && (
                    <Stack spacing={3}>
                        <Typography variant="body2">
                            ニックネーム、メールアドレスはマイページからいつでも変更することができます。
                        </Typography>
                        <Stack spacing={0.5}>
                            <CInputLabel label="ニックネーム" required />
                            <Typography variant="caption">これ終で表示される名前・ニックネーム</Typography>
                            <TextField
                                {...register('nickname')}
                                placeholder={'ニックネーム'}
                                type={'text'}
                                error={!!errors.nickname}
                                helperText={errors.nickname?.message}
                                variant={'outlined'}
                            />
                        </Stack>

                        <Stack spacing={0.5}>
                            <CInputLabel label="メールアドレス" />
                            <Typography variant="caption">これ終で使用されるメールアドレス</Typography>
                            <TextField
                                {...register('email')}
                                placeholder={'メールアドレス'}
                                type={'email'}
                                error={!!errors.email}
                                helperText={errors.email?.message}
                                variant={'outlined'}
                            />
                        </Stack>

                        <LoadingButton
                            variant={'contained'}
                            disabled={!isValid}
                            loading={isSubmitting}
                            onClick={handleSubmit(sendHandler)}>
                            この内容で登録
                        </LoadingButton>
                    </Stack>
                )}
                {mergeAccount && (
                    <Stack spacing={2}>
                        <Typography fontSize="large">
                            Facebookで登録されているメールアドレスと同じメールアドレスのアカウントが存在しています。アカウントを統合しますか？
                        </Typography>
                        <Typography fontSize="medium" color={'red'}>
                            ※ 後でアカウント統合を行うことはできません。
                        </Typography>

                        <TextField placeholder={email || ''} disabled={true} variant={'outlined'} />

                        <Flex justify={'center'} gap={2}>
                            <LoadingButton variant={'contained'} onClick={onClickCreateAccount} fullWidth>
                                新しいアカウントを作成する
                            </LoadingButton>
                            <LoadingButton variant={'contained'} onClick={onClickMergeAccount} fullWidth>
                                統合する
                            </LoadingButton>
                        </Flex>
                    </Stack>
                )}
            </DefaultLayout>
        </>
    )
}
