import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, DialogContent } from '@mui/material'
import { Stack } from '@mui/system'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { CFileUpload } from '~/components/common/cFileUpload/CFileUpload'
import { ClientMemoryAssetCreateDtoSchema, clientMemoryAssetCreateDtoSchema } from '~/types/zodScheme'
import { mediaUrl } from '~/utils/common'

export type CMemoryFileDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: { fileUuids: string[] }) => void
}

export const useCMemoryFileDialog = (props: CMemoryFileDialogProps) => {
    const { isOpen } = props
    const {
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        setValue,
    } = useForm<ClientMemoryAssetCreateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientMemoryAssetCreateDtoSchema),
    })

    const [uploadedImages, setUploadedImages] = useState<Schemas.FileEntities[]>([])

    useEffect(() => {
        setUploadedImages([])
    }, [isOpen])
    const handleUploadFile = (value?: Schemas.FileEntities[]) => {
        if (!value) return

        setUploadedImages((prevImages) => {
            const uniqueUuids = new Set(prevImages.map((image) => image.uuid))
            const uniqueNewImages = value.filter((image) => !uniqueUuids.has(image.uuid))
            const updatedImages = [...prevImages, ...uniqueNewImages]
            setValue(
                'fileUuids',
                updatedImages.map((i) => i.uuid),
            )

            return updatedImages
        })
    }
    return {
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        uploadedImages,
        handleUploadFile,
    }
}

export const CMemoryFileDialog: FC<CMemoryFileDialogProps> = (props: CMemoryFileDialogProps) => {
    const { handleSubmit, errors, isSubmitting, uploadedImages, handleUploadFile } = useCMemoryFileDialog(props)
    const { isOpen, onClose, onSubmit } = props
    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogContent>
                    <Stack spacing={2}>
                        <CFileUpload
                            completionHandler={handleUploadFile}
                            label={'画像アップロード'}
                            error={!!errors.fileUuids}
                            helperText={errors.fileUuids?.message}
                            multiple={true}
                        />
                        <Box display={'flex'} gap={1} overflow={'scroll'}>
                            {uploadedImages.map((image) => (
                                <img
                                    key={image.uuid}
                                    src={mediaUrl(image)}
                                    style={{
                                        width: '100%',
                                        maxWidth: '200px',
                                        maxHeight: '200px',
                                        objectPosition: 'center',
                                        objectFit: 'cover',
                                    }}
                                    alt={image.filename}
                                />
                            ))}
                        </Box>
                        <Stack direction={'row'} justifyContent={'center'} spacing={1}>
                            <Button variant="outlined" onClick={onClose} fullWidth>
                                キャンセル
                            </Button>
                            <LoadingButton
                                variant={'contained'}
                                loading={isSubmitting}
                                disabled={!uploadedImages.length}
                                fullWidth
                                onClick={handleSubmit((dto) => {
                                    onSubmit(dto)
                                })}>
                                保存
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    )
}
