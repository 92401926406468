import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Container, Grid, Link, Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import decorated from '~/assets/image/decorated.svg'
import facebookLogin from '~/assets/image/top/facebook_login.png'
import lineHover from '~/assets/image/top/line_hover.png'
import lineLogin from '~/assets/image/top/line_login.png'
import linePress from '~/assets/image/top/line_press.png'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useAuthState } from '~/store/auth'
import {
    ClientUserMailUpdateDtoSchema,
    clientUserMailUpdateDtoSchema,
    ClientUserMobilePhoneUpdateDtoSchema,
    clientUserMobilePhoneUpdateDtoSchema,
} from '~/types/zodScheme'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useUserLoginManagementPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const { me } = useAuthState()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const redirectStatus = searchParams.get('status')
    const token = searchParams.get('token')

    // initial fetch
    const { data: snsAccountInfo, refetch: refetchSnsAccountInfo } = useQuerySuspense(
        ['user/sns'],
        async () => {
            const res = apiClient.clientSnsAccountGetSnsAccounts()
            return res
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    // SNS連携リダイレクト処理
    useEffect(() => {
        const handleRedirect = async () => {
            if (redirectStatus) {
                if (redirectStatus !== 'success') {
                    await queueDialog({
                        type: 'alert',
                        title: 'SNS連携失敗',
                        text: 'SNS連携に失敗しました。',
                    })
                }
                if (redirectStatus === 'success' && token) {
                    try {
                        await apiClient.clientSnsAccountPostCooperation({ parameter: { token } })
                        queueDialog({
                            type: 'alert',
                            title: 'SNS連携完了',
                            text: 'SNS連携が完了しました。',
                        })
                        refetchSnsAccountInfo()
                    } catch (e) {
                        let message = 'SNS連携に失敗しました'
                        if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                        await queueDialog({
                            type: 'alert',
                            title: 'エラーが発生しました',
                            text: message,
                        })
                    }
                }
            }
            navigate('/user/loginManagement', { replace: true })
        }

        handleRedirect()
    }, [redirectStatus, token, refetchSnsAccountInfo])

    // 0: LINE, 1: Facebook
    const getAccountStatus = (type: 0 | 1) => {
        return snsAccountInfo?.some((account) => account.type === type)
    }

    const lineConnected = getAccountStatus(0)
    const facebookConnected = getAccountStatus(1)

    const [lineIcon, setLineIcon] = useState<string>(lineLogin)
    const linkLine = async () => {
        const url = await apiClient.clientSnsAccountGetCooperationLineURL()
        window.location.href = url
    }
    const linkFacebook = async () => {
        const url = await apiClient.clientSnsAccountGetCooperationFacebookURL()
        window.location.href = url
    }

    const unlinkSns = async (type: 0 | 1) => {
        try {
            const snsName = type === 0 ? 'LINE' : 'Facebook'
            const message = `${snsName}のSNS連携を解除します。${snsName}でログインはできなくなります。よろしいでしょうか？`
            if (
                await queueDialog({
                    type: 'confirm',
                    title: 'SNS連携を解除しますか？',
                    text: message,
                })
            ) {
                const snsInfo = snsAccountInfo?.find((account) => account.type === type)
                if (!snsInfo) throw new Error('アカウントが見つかりません')
                await apiClient.clientSnsAccountDeleteCooperation({ parameter: { type } })
                await refetchSnsAccountInfo()
                await queueDialog({
                    type: 'alert',
                    title: 'SNS連携を解除しました',
                    text: `${snsName}のSNS連携を解除しました。`,
                })
            }
        } catch (e) {
            let message = '送信に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: '送信に失敗しました',
                text: message,
            })
        }
    }

    const [isSendCreatePassword, setIsSendCreatePassword] = useState(false)

    const [isMobilePhoneChange, setIsMobilePhoneChange] = useState(false)

    const {
        register: registerMobilePhone,
        handleSubmit: handleSubmitMobilePhone,
        formState: { errors: errorsMobilePhone, isSubmitting: isSubmittingMobilePhone, isValid: isValidMobilePhone },
        setValue: setValueMobilePhone,
        watch: watchMobilePhone,
    } = useForm<ClientUserMobilePhoneUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientUserMobilePhoneUpdateDtoSchema),
    })

    useEffect(() => {
        setValueMobilePhone('mobilePhone', '')
    }, [])

    const registerMobilePhoneHandler = async (dto: ClientUserMobilePhoneUpdateDtoSchema) => {
        try {
            await apiClient.clientUserUpdateMobilePhone({ requestBody: dto })
            await queueDialog({
                type: 'alert',
                title: '通知を送信しました',
                text: '携帯電話番号変更の通知を送信しました。通知をご確認ください。',
            })
            setIsSendCreatePassword(true)
            setIsMobilePhoneChange(true)
        } catch (e) {
            let message = '送信に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: '送信に失敗しました',
                text: message,
            })
        }
    }

    const resendMobilePhoneHandler = async (dto: Schemas.ClientUserMobilePhoneUpdateDto) => {
        try {
            await apiClient.clientUserUpdateMobilePhone({ requestBody: dto })
            await queueDialog({
                type: 'alert',
                title: '通知を送信しました',
                text: '携帯電話番号変更の通知を送信しました。通知をご確認ください。',
            })
            setIsSendCreatePassword(true)
        } catch (e) {
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: '送信に失敗しました',
            })
        }
    }

    const {
        register: registerEmail,
        handleSubmit: handleSubmitEmail,
        formState: { errors: errorsEmail, isSubmitting: isSubmittingEmail, isValid: isValidEmail },
        setValue: setValueEmail,
        watch: watchEmail,
    } = useForm<ClientUserMailUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientUserMailUpdateDtoSchema),
    })

    useEffect(() => {
        setValueEmail('email', '')
    }, [])

    const registerEmailHandler = async (dto: Schemas.ClientUserMailUpdateDto) => {
        try {
            await apiClient.clientUserUpdateMail({ requestBody: dto })
            await queueDialog({
                type: 'alert',
                title: 'メールを送信しました',
                text: 'メールアドレス登録変更のURLを送信しました。メールをご確認ください。',
            })
            setIsSendCreatePassword(true)
        } catch (e) {
            let message = '送信に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const resendEmailHandler = async (dto: Schemas.ClientUserMailUpdateDto) => {
        try {
            await apiClient.clientUserUpdateMail({ requestBody: dto })
            await queueDialog({
                type: 'alert',
                title: 'メールを再送信しました',
                text: 'メールアドレス登録変更のURLを再送信しました。メールをご確認ください。',
            })
            setIsSendCreatePassword(true)
        } catch (e) {
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: '送信に失敗しました',
            })
        }
    }

    const mobilePhoneValue = watchMobilePhone('mobilePhone')
    const emailValue = watchEmail('email')

    const resetScreen = () => {
        setIsSendCreatePassword(false)
        setIsMobilePhoneChange(false)
        setValueMobilePhone('mobilePhone', '')
        setValueEmail('email', '')
    }

    return {
        me,
        lineConnected,
        facebookConnected,
        lineIcon,
        setLineIcon,
        linkLine,
        linkFacebook,
        unlinkSns,
        isSendCreatePassword,
        setIsSendCreatePassword,
        registerMobilePhone,
        handleSubmitMobilePhone,
        errorsMobilePhone,
        isSubmittingMobilePhone,
        isValidMobilePhone,
        registerMobilePhoneHandler,
        resendMobilePhoneHandler,
        registerEmail,
        handleSubmitEmail,
        errorsEmail,
        isSubmittingEmail,
        isValidEmail,
        registerEmailHandler,
        resendEmailHandler,
        isMobilePhoneChange,
        mobilePhoneValue,
        emailValue,
        resetScreen,
    }
}

export const UserLoginManagementPage: FC = () => {
    const {
        me,
        lineConnected,
        facebookConnected,
        lineIcon,
        setLineIcon,
        linkLine,
        linkFacebook,
        unlinkSns,
        isSendCreatePassword,
        registerMobilePhone,
        handleSubmitMobilePhone,
        errorsMobilePhone,
        isSubmittingMobilePhone,
        isValidMobilePhone,
        registerMobilePhoneHandler,
        resendMobilePhoneHandler,
        registerEmail,
        handleSubmitEmail,
        errorsEmail,
        isSubmittingEmail,
        isValidEmail,
        registerEmailHandler,
        resendEmailHandler,
        isMobilePhoneChange,
        mobilePhoneValue,
        emailValue,
        resetScreen,
    } = useUserLoginManagementPage()
    return (
        <DefaultLayout title={'ログイン情報管理'} breadcrumbList={[]}>
            <Container sx={{ fontSize: '1.125rem', px: 0 }}>
                <Stack spacing={4}>
                    {isSendCreatePassword ? (
                        <Stack spacing={4}>
                            {isMobilePhoneChange ? (
                                <>
                                    <Typography variant="body2">
                                        携帯電話番号変更の通知を送信しました。通知をご確認ください。
                                        <br />
                                        この時点ではご登録の携帯電話番号は変更されておりません。
                                        <br />
                                        変更前の携帯電話番号でログインして、通知に記載しているURLにアクセスしてください。
                                    </Typography>
                                    <LoadingButton
                                        variant={'outlined'}
                                        onClick={handleSubmitMobilePhone(resendMobilePhoneHandler)}>
                                        電話番号へ再送信
                                    </LoadingButton>
                                </>
                            ) : (
                                <Stack spacing={4}>
                                    <Typography variant="body2">
                                        メールアドレス登録のURLを送信しました。メールをご確認ください。
                                        <br />
                                        ログインして、メールに記載しているURLにアクセスしてください。
                                    </Typography>
                                    <LoadingButton variant={'outlined'} onClick={handleSubmitEmail(resendEmailHandler)}>
                                        ご登録いただいたメールへ再送信
                                    </LoadingButton>
                                </Stack>
                            )}
                            <img src={decorated} width={'100%'} />
                            <Button fullWidth variant={'contained'} onClick={resetScreen}>
                                入力画面へもどる
                            </Button>
                        </Stack>
                    ) : (
                        <>
                            <Typography variant="h3" fontSize={'20px'} fontFamily={'Noto Sans JP'}>
                                SNS連携
                            </Typography>
                            <Typography variant="body2" fontFamily={'Noto Sans JP'}>
                                SNS連携を行うとSNSでのログインができるようになり、SNSを通じてつながり申請を簡単に行えるようになります。
                            </Typography>
                            <Grid container justifyContent={'center'} direction={'column'}>
                                <Grid item xs={12} sm={6} py={1}>
                                    <Button
                                        disabled={lineConnected && !facebookConnected && !me?.email && !me?.mobilePhone}
                                        variant={lineConnected ? 'contained' : 'outlined'}
                                        color={lineConnected ? 'error' : 'primary'}
                                        fullWidth
                                        startIcon={<img src={lineIcon} height={'24px'} />}
                                        onClick={() => (lineConnected ? unlinkSns(0) : linkLine())}
                                        onMouseEnter={() => setLineIcon(lineHover)}
                                        onMouseLeave={() => setLineIcon(lineLogin)}
                                        onMouseDown={() => setLineIcon(linePress)}
                                        onMouseUp={() => setLineIcon(lineHover)}>
                                        <Typography
                                            variant="body2"
                                            fontWeight={'bold'}
                                            fontFamily={'Noto Sans JP'}
                                            color={lineConnected ? '#FFFFFF' : ''}>
                                            LINE 連携{lineConnected ? '解除' : ''}
                                        </Typography>
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6} py={1}>
                                    <Button
                                        disabled={facebookConnected && !lineConnected && !me?.email && !me?.mobilePhone}
                                        variant={facebookConnected ? 'contained' : 'outlined'}
                                        color={facebookConnected ? 'error' : 'primary'}
                                        fullWidth
                                        startIcon={<img src={facebookLogin} height={'24px'} />}
                                        onClick={() => (facebookConnected ? unlinkSns(1) : linkFacebook())}>
                                        <Typography
                                            variant="body2"
                                            fontWeight={'bold'}
                                            fontFamily={'Noto Sans JP'}
                                            color={facebookConnected ? '#FFFFFF' : ''}>
                                            Facebook 連携{facebookConnected ? '解除' : ''}
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                            <Stack sx={{ fontSize: 'large', px: 0 }}>
                                <Stack spacing={4}>
                                    <img src={decorated} width={'100%'} />
                                    <Stack spacing={1}>
                                        <CInputLabel label={`メールアドレス${me?.email ? '変更' : '登録'}`} />
                                        <TextField
                                            id={'email'}
                                            required={true}
                                            {...registerEmail('email')}
                                            placeholder={'メールアドレス'}
                                            error={!!errorsEmail.email}
                                            helperText={errorsEmail.email?.message}
                                            variant={'outlined'}
                                        />
                                        <Typography variant="caption" fontSize="medium">
                                            入力していただいたメールアドレス宛にリンクが送信されます。
                                            <br />
                                            上記のリンクを押す時点で登録が完了となります。
                                        </Typography>
                                    </Stack>
                                    <LoadingButton
                                        variant={'contained'}
                                        disabled={!isValidEmail || !emailValue}
                                        loading={isSubmittingEmail}
                                        sx={{ height: '3rem' }}
                                        onClick={handleSubmitEmail(registerEmailHandler)}>
                                        送信
                                    </LoadingButton>
                                </Stack>

                                <Stack mt={3} spacing={2}>
                                    <img src={decorated} width={'100%'} style={{ marginBottom: '1.25rem' }} />
                                    <Stack spacing={1}>
                                        <CInputLabel label={`携帯電話番号${me?.mobilePhone ? '変更' : '登録'}`} />
                                        <Typography variant="caption">ハイフン(-)は除いて入力してください</Typography>
                                        <TextField
                                            id={'mobilePhone'}
                                            required={true}
                                            {...registerMobilePhone('mobilePhone')}
                                            placeholder={'携帯電話番号'}
                                            error={!!errorsMobilePhone.mobilePhone}
                                            helperText={errorsMobilePhone.mobilePhone?.message}
                                            variant={'outlined'}
                                        />
                                        <Typography variant="caption" fontSize="medium">
                                            入力していただいた電話番号宛にリンクが送信されます。
                                            <br />
                                            上記のリンクを押す時点で登録が完了となります。
                                        </Typography>
                                    </Stack>
                                    <LoadingButton
                                        variant={'contained'}
                                        disabled={!isValidMobilePhone || !mobilePhoneValue}
                                        loading={isSubmittingMobilePhone}
                                        sx={{ height: '3rem' }}
                                        onClick={handleSubmitMobilePhone(registerMobilePhoneHandler)}>
                                        送信
                                    </LoadingButton>
                                </Stack>
                            </Stack>
                        </>
                    )}
                </Stack>
            </Container>
            <Container sx={{ my: 4, fontSize: 'large', px: 0 }}>
                <Stack spacing={4} sx={{ alignItems: 'center' }}>
                    <img src={decorated} width={'100%'} />
                    <Link color={'#77618B'} href="/inquiry">
                        お問合せ
                    </Link>
                </Stack>
            </Container>
        </DefaultLayout>
    )
}
