import ReactGA from 'react-ga4'

type GA_CategoryType = 'login'
type GA_ActionType = 'mail_login' | 'facebook_login' | 'line_google' | 'phone_login'
type GA_LabelType = 'mail' | 'facebook' | 'line' | 'phone'

export const useGA = () => {
    const sendGAEvent = (category: GA_CategoryType, actionName: GA_ActionType, value: GA_LabelType) => {
        ReactGA.event({
            category,
            action: actionName,
            label: value,
        })
    }
    return { sendGAEvent }
}
