import { useDisclosure } from '@chakra-ui/hooks'
import ErrorIcon from '@mui/icons-material/Error'
import { Avatar, Box, Button, Chip, Container, Grid, IconButton, Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import helpIcon from '~/assets/image/connect/helpIcon.svg'
// import connectGift from '~/assets/image/icon/gift.svg'
import connectLine from '~/assets/image/icon/line.svg'
import iconMail from '~/assets/image/icon/mail.svg'
import iconMailDisabled from '~/assets/image/icon/mailDisabled.svg'
import iconMemory from '~/assets/image/icon/memory.svg'
import iconTomb from '~/assets/image/icon/tomb.svg'
import tutorialMain11 from '~/assets/image/tutorial/connect/tutorial-11/main.png'
import tutorialTitle11 from '~/assets/image/tutorial/connect/tutorial-11/title.png'
import tutorialMain12 from '~/assets/image/tutorial/connect/tutorial-12/main.png'
import tutorialTitle12 from '~/assets/image/tutorial/connect/tutorial-12/title.png'
import tutorialMain13 from '~/assets/image/tutorial/connect/tutorial-13/main.png'
import tutorialTitle13 from '~/assets/image/tutorial/connect/tutorial-13/title.png'
import { CConnectLabel } from '~/components/functional/connect/cConnectLabel/CConnectLabel'
import { CProfileBox } from '~/components/functional/cProfileBox/CProfileBox'
import { CTutorialDialog, TutorialData } from '~/components/functional/tutorial/cTutorialDialog/CTutorialDialog'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useAuthState } from '~/store/auth'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useConnectListConnectUuidIndexPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const params = useParams()
    const connectUuid = params.connectUuid
    const navigate = useNavigate()
    const { me, isTutorialAfterPermit, setIsTutorialAfterPermit } = useAuthState()

    // initial fetch
    const { data: connect } = useQuerySuspense(
        [`/connectList/${connectUuid}`],
        async () => {
            if (!connectUuid) throw new Error()
            const connect = await apiClient.clientConnectGetConnect({
                parameter: { connectUuid },
            })
            return connect
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const sendInviteHandler = async () => {
        try {
            if (!connectUuid) return
            const confirm = await queueDialog({
                type: 'confirm',
                title: '招待メール送信確認',
                text: '招待メールを送信しますか？',
            })
            if (confirm) {
                await apiClient.clientConnectInvite({
                    parameter: { connectUuid: connectUuid },
                })
                await queueDialog({
                    type: 'alert',
                    title: '招待メール送信',
                    text: '招待メールを送信しました',
                })
            }
        } catch (e) {
            let message = '送信に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const receivedMessageHandler = () => {
        if (!connect) return
        navigate(`/connectList/${connect.uuid}/receivedMessage`)
    }
    const giftHandler = () => {
        navigate('/gift')
    }
    const copyHandler = () => {
        navigator.clipboard.writeText(`${process.env.BASE_URL}/invite/${me!.invite}`)
    }

    const deleteHandler = async () => {
        if (!connectUuid) return
        try {
            const message =
                'つながりを削除すると共有していた思い出が利用できなくなります。また、削除したつながりは復元できません。'
            if (
                await queueDialog({
                    type: 'confirm',
                    title: 'つながりを削除しますか？',
                    text: message,
                })
            ) {
                await apiClient.clientConnectDelete({ parameter: { connectUuid: connectUuid } })
                const message = '削除しました'
                await queueDialog({
                    type: 'alert',
                    title: '削除',
                    text: message,
                })
                navigate('/connectList', { replace: true })
            }
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    // tutorial
    const [isDisplayTutorial, setIsDisplayTutorial] = useState(
        isTutorialAfterPermit && tutorialAfterPermitData.length > 0 && connect?.isConnectUser,
    )
    const { isOpen: tutorialDialogIsOpen, onOpen: tutorialDialogOnOpen, onClose: tutorialDialogOnClose } = useDisclosure()
    const openTutorialHandler = () => {
        tutorialDialogOnOpen()
    }
    const closeTutorialHandler = () => {
        setIsTutorialAfterPermit(false)
        setIsDisplayTutorial(false)
        tutorialDialogOnClose()
    }
    useEffect(() => {
        if (isDisplayTutorial) tutorialDialogOnOpen()
    }, [])

    const openManageUserMessageHandler = async () => {
        await queueDialog({
            type: 'alert',
            title: 'お墓の管理者について',
            text: '命日を入力することで亡くなった方の思い出、お墓と思い出のコメントを管理することができます。※亡くなったことが伝わりますのでくれぐれも間違えて入力しないようご注意ください。',
        })
    }

    return {
        navigate,
        connectUuid,
        connect,
        sendInviteHandler,
        receivedMessageHandler,
        giftHandler,
        copyHandler,
        deleteHandler,

        tutorialDialogIsOpen,
        openTutorialHandler,
        closeTutorialHandler,
        openManageUserMessageHandler,
    }
}

export const ConnectListConnectUuidIndexPage: FC = () => {
    const {
        navigate,
        connect,
        sendInviteHandler,
        // giftHandler,
        deleteHandler,

        tutorialDialogIsOpen,
        openTutorialHandler,
        closeTutorialHandler,
        openManageUserMessageHandler,
    } = useConnectListConnectUuidIndexPage()
    return (
        <>
            <DefaultLayout maxWidth={'md'} title={''} breadcrumbList={[]} tabValue={'connectList'}>
                {connect && connect.isManageUser && (
                    <Box bgcolor={'#FDEDEE'} p={1} width={'100%'}>
                        <Button color={'error'} variant={'text'} onClick={openManageUserMessageHandler}>
                            <Stack direction={'row'} justifyContent={'start'} alignItems={'start'} spacing={1}>
                                <ErrorIcon color={'error'} />
                                <Typography
                                    variant="body2"
                                    color={'error'}
                                    textAlign={'start'}
                                    sx={{ textDecoration: 'underline', textUnderlineOffset: '4px' }}>
                                    このお墓の管理を依頼されています。詳細について確認してください。
                                </Typography>
                            </Stack>
                        </Button>
                    </Box>
                )}
                <Container>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
                        <Grid container direction="row" justifyContent="start" alignItems="center" sx={{ mb: 1 }} spacing={1}>
                            {connect && connect.isManageUser && (
                                <Grid item>
                                    <Chip
                                        color="error"
                                        label={
                                            <Typography variant="body1" sx={{ color: '#000000' }}>
                                                管理者
                                            </Typography>
                                        }
                                        sx={{ p: '2px 16px' }}
                                    />
                                </Grid>
                            )}
                            {connect!.tags.map((tag: Schemas.ConnectTagEntities) => (
                                <Grid item key={tag.uuid}>
                                    <Chip
                                        label={
                                            <Typography variant="body1" sx={{ color: '#000000' }}>
                                                {tag.name}
                                            </Typography>
                                        }
                                        sx={{ p: '2px 16px' }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <IconButton onClick={openTutorialHandler}>
                            <img src={helpIcon} />
                        </IconButton>
                    </Stack>
                    <Stack spacing={5} sx={{ pt: 2 }}>
                        <Stack spacing={2}>
                            <Stack direction="row" justifyContent="center" alignItems="center">
                                {connect && connect.deathDay && connect.deathDay.length > 0 ? (
                                    <Avatar
                                        variant="rounded"
                                        alt={connect!.name}
                                        src={mediaUrl(connect!.file, true)}
                                        sx={{ border: '6px solid #AF985A', borderRadius: '5px', width: 130, height: 160 }}
                                    />
                                ) : (
                                    <Avatar
                                        alt={connect!.name}
                                        src={mediaUrl(connect!.file, true)}
                                        sx={{ width: 152, height: 152 }}
                                    />
                                )}
                            </Stack>
                            <Stack spacing={0.5}>
                                <Typography variant="h3" textAlign={'center'}>
                                    {connect!.name}
                                </Typography>
                                <Typography variant="body2" textAlign={'center'}>
                                    {connect!.sei}
                                </Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="center" alignItems="center">
                                <CConnectLabel connect={connect!} />
                            </Stack>
                        </Stack>
                        {connect && connect.isConnectUser ? (
                            <>
                                {connect.isConnectUserDeath ? (
                                    <Stack spacing={2}>
                                        <Stack direction={'row'} spacing={1}>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                startIcon={<img src={iconTomb} alt="お参りする" />}
                                                onClick={() => {
                                                    navigate(`/connectList/${connect!.uuid}/grave`)
                                                }}>
                                                お参りする
                                            </Button>

                                            {!connect.isConnectUserDummy && (
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    startIcon={<img src={connectLine} alt="メッセージをみる" />}
                                                    onClick={() => navigate(`/connectList/${connect!.uuid}/message`)}>
                                                    メッセージ
                                                </Button>
                                            )}
                                        </Stack>

                                        {connect.isManageUser && (
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                startIcon={<img src={iconMemory} alt="思い出を管理する" />}
                                                onClick={() => navigate(`/connectList/${connect!.uuid}/memory`)}>
                                                思い出を管理する
                                            </Button>
                                        )}
                                    </Stack>
                                ) : (
                                    <Stack spacing={2}>
                                        {/* <Button
                                            variant="contained"
                                            fullWidth
                                            startIcon={<img src={connectGift} alt="ギフトを贈る" />}
                                            onClick={() => giftHandler()}>
                                            ギフトを贈る
                                        </Button> */}
                                        {!connect.isConnectUserDummy && (
                                            <Stack direction={'row'} spacing={1}>
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    disabled={true}
                                                    startIcon={<img src={iconMailDisabled} alt="招待する" />}>
                                                    招待する
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    startIcon={<img src={connectLine} alt="メッセージを贈る" />}
                                                    onClick={() => navigate(`/connectList/${connect!.uuid}/message`)}>
                                                    メッセージ
                                                </Button>
                                            </Stack>
                                        )}
                                    </Stack>
                                )}
                            </>
                        ) : (
                            <Stack spacing={2}>
                                {/* <Button
                                    variant="contained"
                                    fullWidth
                                    startIcon={<img src={connectGift} alt="ギフトを贈る" />}
                                    onClick={() => giftHandler()}>
                                    ギフトを贈る
                                </Button> */}
                                <Stack direction={'row'} spacing={1}>
                                    {connect!.email ? (
                                        <>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                disabled={connect!.isSendInvite === 1}
                                                startIcon={
                                                    connect!.isSendInvite !== 1 ? (
                                                        <img src={iconMail} alt="招待する" />
                                                    ) : (
                                                        <img src={iconMailDisabled} alt="招待する" />
                                                    )
                                                }
                                                onClick={() => sendInviteHandler()}>
                                                招待する
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                disabled={true}
                                                startIcon={<img src={iconMailDisabled} alt="招待する" />}>
                                                招待する
                                            </Button>
                                        </>
                                    )}
                                    <Button
                                        variant="contained"
                                        startIcon={<img src={connectLine} alt="メッセージを贈る" />}
                                        fullWidth
                                        onClick={() => navigate(`/connectList/${connect!.uuid}/message`)}>
                                        メッセージ
                                    </Button>
                                </Stack>
                            </Stack>
                        )}
                        <Stack spacing={4}>
                            <Box borderRadius={'8px'} padding={1} sx={{ background: '#EFEAE6' }}>
                                <Typography variant={'body2'}>メールアドレス</Typography>
                                {connect && connect.email ? (
                                    <>
                                        <Typography variant={'body2'}>{connect.email}</Typography>
                                    </>
                                ) : (
                                    <>
                                        <Typography variant="body2" color={'#77618B'}>
                                            メールアドレスを登録することで、メールを送ってこれ終に招待してつながることができるようになります。
                                        </Typography>
                                    </>
                                )}
                            </Box>
                            <CProfileBox label={'生年月日'} value={[(connect && connect.birthday) || '']} />
                            <CProfileBox label={'メモ'} value={[(connect && connect.memo) || '']} />
                        </Stack>
                        <Stack spacing={2} sx={{ pt: 2 }}>
                            <Button
                                variant={'contained'}
                                onClick={() =>
                                    navigate(`/connectList/${connect!.uuid}/edit`, {
                                        state: { isDummy: connect?.isConnectUserDummy },
                                    })
                                }>
                                編集
                            </Button>
                            <Button variant={'contained'} color="error" onClick={deleteHandler}>
                                つながりを解除
                            </Button>
                        </Stack>
                    </Stack>
                </Container>
            </DefaultLayout>
            <CTutorialDialog
                isOpen={tutorialDialogIsOpen}
                onClose={closeTutorialHandler}
                tutorialDataList={tutorialAfterPermitData}
            />
        </>
    )
}
export const tutorialAfterPermitData: TutorialData[] = [
    {
        imageTitleSrc: tutorialTitle11,
        imageSrc: tutorialMain11,
        imageAlt: '管理者を登録',
        text: '万一の際に自身の代わりにお墓や思い出の管理をお願いできます。',
        buttonText: '次へ',
    },
    {
        imageTitleSrc: tutorialTitle12,
        imageSrc: tutorialMain12,
        imageAlt: '管理者を登録',
        text: '「つながっています」状態のユーザーを選択し、編集ボタンをタップします。',
        buttonText: '次へ',
    },
    {
        imageTitleSrc: tutorialTitle13,
        imageSrc: tutorialMain13,
        imageAlt: '管理者を登録',
        text: '編集画面で没後管理権限を権限ありにすると管理者に設定できます。',
        buttonText: '閉じる',
    },
]
