import { Stack, Typography } from '@mui/material'
import { type FC, type ReactNode } from 'react'

import confirmConnect from '~/assets/image/help/mypage/connections/confirmConnect.png'
import confirmConnectThree from '~/assets/image/help/mypage/connections/confirmConnectThree.png'
import confirmConnectTwo from '~/assets/image/help/mypage/connections/confirmConnectTwo.png'
import connectRules from '~/assets/image/help/mypage/connections/connectRules.png'
import connectRulesTwo from '~/assets/image/help/mypage/connections/connectRulesTwo.png'
// import giftImageEight from '~/assets/image/help/mypage/connections/giftImageEight.png'
// import giftImageFive from '~/assets/image/help/mypage/connections/giftImageFive.png'
// import giftImageFour from '~/assets/image/help/mypage/connections/giftImageFour.png'
// import giftImageNine from '~/assets/image/help/mypage/connections/giftImageNine.png'
// import giftImageOne from '~/assets/image/help/mypage/connections/giftImageOne.png'
// import giftImageSeven from '~/assets/image/help/mypage/connections/giftImageSeven.png'
// import giftImageSix from '~/assets/image/help/mypage/connections/giftImageSix.png'
// import giftImageTenFirst from '~/assets/image/help/mypage/connections/giftImageTenFirst.png'
// import giftImageTenSecond from '~/assets/image/help/mypage/connections/giftImageTenSecond.png'
// import giftImageThree from '~/assets/image/help/mypage/connections/giftImageThree.png'
import graveVisitImageEight from '~/assets/image/help/mypage/connections/graveVisitImageEight.png'
import graveVisitImageFive from '~/assets/image/help/mypage/connections/graveVisitImageFive.png'
import graveVisitImageFour from '~/assets/image/help/mypage/connections/graveVisitImageFour.png'
import graveVisitImageNine from '~/assets/image/help/mypage/connections/graveVisitImageNine.png'
import graveVisitImageOne from '~/assets/image/help/mypage/connections/graveVisitImageOne.png'
import graveVisitImageSeven from '~/assets/image/help/mypage/connections/graveVisitImageSeven.png'
import graveVisitImageSix from '~/assets/image/help/mypage/connections/graveVisitImageSix.png'
import graveVisitImageThree from '~/assets/image/help/mypage/connections/graveVisitImageThree.png'
import graveVisitImageTwo from '~/assets/image/help/mypage/connections/graveVisitImageTwo.png'
import registerConnect from '~/assets/image/help/mypage/connections/registerConnect.png'
import registerConnectThree from '~/assets/image/help/mypage/connections/registerConnectThree.png'
import registerConnectTwo from '~/assets/image/help/mypage/connections/registerConnectTwo.png'
import tags from '~/assets/image/help/mypage/connections/tags.png'
import tagsFive from '~/assets/image/help/mypage/connections/tagsFive.png'
import tagsFour from '~/assets/image/help/mypage/connections/tagsFour.png'
import tagsOne from '~/assets/image/help/mypage/connections/tagsOne.png'
import tagsThree from '~/assets/image/help/mypage/connections/tagsThree.png'
import tagsTwo from '~/assets/image/help/mypage/connections/tagsTwo.png'
import hamburgerIcon from '~/assets/image/help/mypage/hamburgerIcon.png'
import { CHelpImage } from '~/components/functional/help/cHelpImage/CHelpImage'

import { CHelpCommonLink } from '../cHelpCommonLink/CHelpCommonLink'
import { CHelpCustomList } from '../cHelpCustomList/CHelpCustomList'
import { CHelpLayout } from '../cHelpLayout/CHelpLayout'

type ConnectHelpItems = {
    heading?: ReactNode
    content?: ReactNode
    image?: string
    imageTwo?: string
}
type ConnectSectionItems = {
    id: string
    title: string
    body?: ReactNode
    children?: ReactNode
}
export type CConnectHelpProps = {
    onLinkClick: (sectionName: string, pathName: string, newTab?: boolean) => void
}

export const useCConnectHelp = (onLinkClick: (sectionName: string, pathName: string, newTab?: boolean) => void) => {
    const connectionItems = (
        <CHelpCustomList
            listStyleType="disc"
            listItems={[
                <>
                    ニックネーム
                    <br />
                    必ず入力してください。つながりに登録する相手の呼び名を入力してください。
                </>,
                <>
                    フリガナ
                    <br />
                    呼び名のフリガナをご登録ください。アドレス帳で50音順に表示されます。
                </>,
                <>
                    メールアドレス
                    <br />
                    登録することで相手をこれ終に招待してつながることができます。
                </>,
                <>
                    ラベル
                    <br />
                    登録する相手とあなたの関係をラベルで管理することができます。詳しくは
                    <CHelpCommonLink onClick={() => onLinkClick('tags', 'connectList', false)}>こちら</CHelpCommonLink>
                </>,
                <>
                    生年月日
                    <br />
                    つながりに登録する相手の生年月日を入力してください。
                </>,
                // <>
                //     住所
                //     <br />
                //     登録するとギフトを送ることができるようになります。ギフト送付先の住所をご入力ください。
                // </>,
                <>
                    メモ
                    <br />
                    必要である場合ご自由に入力してください。
                </>,
            ]}
        />
    )

    const confirmConnectionItems: ConnectHelpItems[] = [
        {
            heading: '申請されたつながり',
            content: (
                <>
                    相手からつながりの申請が来た場合にここに表示されます。申請の承認・辞退ができます。承認した場合は相手に通知が行きますが、辞退した場合は相手に通知されることはありません
                </>
            ),
            image: confirmConnectTwo,
        },
        {
            heading: '申請したつながり',
            content: (
                <>
                    あなたが申請しているつながりが表示されます。相手によって承認または辞退された申請はこのリストの表示から削除されます。
                    <br />
                    また、相手が承認または辞退する前であれば、申請を取り下げることも可能です。申請の取り下げは相手に通知されることはありません。
                </>
            ),
            image: confirmConnectThree,
        },
    ]
    const confirmConnectionList = confirmConnectionItems.map((it, idx) => {
        return (
            <Stack key={`confirmConnectionItems-${idx}`} spacing={'0.625rem'}>
                <Typography variant={'subtitle1'}>{it.heading}</Typography>
                <Typography variant={'body2'}>{it.content}</Typography>
                {it.image && <CHelpImage src={it.image} />}
            </Stack>
        )
    })

    const tagsListItems = (
        <CHelpCustomList
            listStyleType="decimal"
            paddingBottom="12px"
            listItems={[
                <>
                    つながりページの <b>ラベル管理</b> ボタンをタップします。
                    <CHelpImage src={tagsOne} />
                </>,
                <>
                    ラベル一覧の下の <b>ラベルを新規登録</b> ボタンをタップします。
                    <CHelpImage src={tagsTwo} />
                </>,
                <>
                    登録したいラベル名を入力し、登録をタップします。
                    <CHelpImage src={tagsThree} />
                </>,
                <>
                    登録したラベルの名前を変更したい場合はラベルの右側の <b>登録</b>{' '}
                    ボタンをタップするとラベル名の編集ができます。
                    <br />
                    ラベルを削除したい場合は削除ボタンを押してください。
                    <CHelpImage src={tagsFour} />
                </>,
                <>
                    登録したラベルは、つながりの登録または繋がってる相手のプロフィール編集画面から追加することができます。
                    <CHelpImage src={tagsFive} />
                </>,
            ]}
        />
    )
    const graveVisitItemsList = (
        <CHelpCustomList
            listStyleType="decimal"
            paddingBottom="12px"
            listItems={[
                <>
                    つながっている人のページから <b>お参りする</b> ボタンをタップします。
                    <CHelpImage src={graveVisitImageOne} />
                </>,
                <>
                    <b>御供物を手向ける</b> ボタンをタップします。
                    <CHelpImage src={graveVisitImageTwo} />
                </>,
                <>
                    お供えしたい品を選択します。
                    <CHelpImage src={graveVisitImageThree} />
                </>,
                <>
                    お墓の画像上でお供え物を置きたい場所をタップし、お供え物の位置を指定してください。位置が指定できたら{' '}
                    <b>カートに入れる</b> ボタンをタップします。
                    <CHelpImage src={graveVisitImageFour} />
                </>,
                <>
                    <b>お供えする日時を指定する</b> ボタンをタップします。
                    <CHelpImage src={graveVisitImageFive} />
                </>,
                <>
                    お供え物をすぐにお供えするか、指定の日からお供えするかを選択できます。希望日があればお供えを開始する日を指定してください。
                    <br />
                    設定が完了したら、<b>コメント登録</b> をタップします。
                    <CHelpImage src={graveVisitImageSix} />
                </>,
                <>
                    お供えと共に送る言葉をコメント欄に入力して <b>お支払い方法を選択</b> ボタンをタップします。
                    <CHelpImage src={graveVisitImageSeven} />
                </>,
                <>
                    お支払い方法は以下の2点です。
                    <CHelpCustomList listStyleType={'disc'} listItems={[<>銀行振込</>, <>クレジットカード</>]} />
                    クレジットカードを選択した場合は、カード情報をご入力ください。
                    <br />
                    銀行振込を選択される場合は、表示される振込先情報をご確認の上、期日までに振り込みをお願いします。
                    <br />
                    お支払い方法の設定が終わったら <b>注文内容を確認</b> ボタンをタップしてください。
                    <CHelpImage src={graveVisitImageEight} />
                </>,
                <>
                    ご注文内容にお間違いがなければ <b>注文を確定する</b> ボタンをタップしてください。
                    これでお供え物の注文が完了です。
                    <br />
                    ※銀行振込を選択された場合、必ず期日までの振込をお願いいたします。
                    <CHelpImage src={graveVisitImageNine} />
                </>,
            ]}
        />
    )

    // const giftItemsList = (
    //     <CHelpCustomList
    //         listStyleType={'decimal'}
    //         paddingBottom={'12px'}
    //         listItems={[
    //             <>
    //                 つながっている人のページから <b>ギフトを贈る</b> ボタンをタップします。
    //                 <CHelpImage src={giftImageOne} />
    //             </>,
    //             <>ギフト一覧から、贈りたいギフトを選択します。</>,
    //             <>
    //                 選択したギフトの詳細を確認して <b>カートに入れる</b> ボタンをタップします。
    //                 <CHelpImage src={giftImageThree} />
    //             </>,
    //             <>
    //                 カートの中を確認し <b>お届け先を指定</b> ボタンをタップします。
    //                 <CHelpImage src={giftImageFour} />
    //             </>,
    //             <>
    //                 <b>お届け先を選択</b> ボタンをタップします。
    //                 <CHelpImage src={giftImageFive} />
    //             </>,
    //             <>
    //                 つながっている人の中からこのギフトを贈る人を選択し <b>確定</b> ボタンをタップします。
    //                 <CHelpImage src={giftImageSix} />
    //             </>,
    //             <>
    //                 お届け先の選択を終えたら <b>お届け日時を指定</b> ボタンをタップしてください。
    //                 <CHelpImage src={giftImageSeven} />
    //             </>,
    //             <>
    //                 お届けのタイミングを設定してください。
    //                 <CHelpCustomList
    //                     listStyleType={'disc'}
    //                     paddingBottom={'12px'}
    //                     listItems={[
    //                         <>
    //                             <b>没後に届くギフト</b>
    //                             <br />
    //                             自分が亡くなった後、お世話になった方へ発送される感謝の気持ちを伝えるギフトです。
    //                         </>,
    //                         <>
    //                             <b>お届け日を設定</b>
    //                             <br />
    //                             命日など気持ちを届けたいタイミングに合わせてギフトを送ることができます※お届けの日は10日後から指定が可能です。
    //                         </>,
    //                     ]}
    //                 />
    //                 設定が完了したら、 <b>お支払い方法を選択</b> ボタンをタップします。
    //                 <CHelpImage src={giftImageEight} />
    //             </>,
    //             <>
    //                 お支払い方法は以下の2点です。
    //                 <CHelpCustomList listStyleType={'disc'} listItems={[<>銀行振込</>, <>クレジットカード</>]} />
    //                 クレジットカードを選択した場合は、カード情報をご入力ください。
    //                 <br />
    //                 銀行振込を選択される場合は、表示される振込先情報をご確認の上、期日までに振り込みをお願いします。
    //                 <br />
    //                 お支払い方法の設定が終わったら <b>注文内容を確認</b> ボタンをタップしてください。
    //                 <CHelpImage src={giftImageNine} />
    //             </>,
    //             <>
    //                 ご注文内容にお間違いがなければ <b>注文を確定する</b> ボタンをタップしてください。
    //                 これでギフトの注文が完了です。
    //                 <br />
    //                 ※銀行振込を選択された場合、必ず期日までの振込をお願いいたします。
    //                 <CHelpImage src={giftImageTenFirst} />
    //                 <CHelpImage src={giftImageTenSecond} />
    //             </>,
    //         ]}
    //     />
    // )

    const connectSectionItems: ConnectSectionItems[] = [
        {
            id: 'connectRules',
            title: 'つながるとできること',
            body: (
                <>
                    <Typography variant={'body2'}>つながりを登録すると以下の機能が使用できます。</Typography>
                    <CHelpCustomList listStyleType={'disc'} listItems={[<>思い出の共有</>, <>お互いのお墓参り</>]} />
                </>
            ),
            children: (
                <>
                    <Stack spacing={'0.625rem'}>
                        <Typography variant={'subtitle1'}>つながりの確認</Typography>
                        <Typography variant={'body2'}>つながりはつながりページの一覧に50音順で表示されます。</Typography>
                        <CHelpImage src={connectRules} />
                        <Typography variant={'body2'}>
                            名前右の色のついた丸はそれぞれつながり申請の状況を示しています。
                        </Typography>
                        <CHelpImage src={connectRulesTwo} />
                    </Stack>
                </>
            ),
        },
        {
            id: 'registerConnections',
            title: 'つながり登録・申請方法',
            children: (
                <>
                    <Stack spacing={'0.625rem'} pt={'0.5rem'}>
                        <Typography variant={'subtitle1'}>登録方法</Typography>
                        <CHelpCustomList
                            listStyleType="decimal"
                            paddingBottom={'18px'}
                            listItems={[
                                <>
                                    つながりページより、<b>つながりを登録</b>ボタンをタップします。
                                    <CHelpImage src={registerConnect} />
                                </>,
                                <>
                                    登録画面に遷移します。相手のプロフィール画像やニックネームなど、相手の情報を入力してください。
                                    {connectionItems}
                                </>,
                                <>画面最下部の登録ボタンを押すと、つながり登録が完了です。</>,
                            ]}
                        />
                    </Stack>
                    <Stack spacing={'0.625rem'} pt={'0.5rem'}>
                        <Typography variant={'subtitle1'}>申請方法</Typography>
                        <CHelpCustomList
                            listStyleType={'decimal'}
                            fontSize={'18px'}
                            paddingBottom={'8px'}
                            listItems={[
                                <>つながりページより、申請したい相手のプロフィールページを開きます。</>,
                                <>
                                    プロフィールページにある <b>招待する</b>{' '}
                                    ボタンをタップしてください。相手にこれ終への招待が送られます。
                                    <br />
                                    ※メールアドレスの登録がお済みでない場合、このボタンを押すことができません。
                                    <CHelpImage src={registerConnectTwo} />
                                </>,
                                <>
                                    表示がつながり承認待ちになったら申請完了です。申請状況の確認・申請を取り消したい場合は
                                    <CHelpCommonLink onClick={() => onLinkClick('confirmConnections', 'connectList', false)}>
                                        こちら
                                    </CHelpCommonLink>
                                    をご確認ください。
                                    <CHelpImage src={registerConnectThree} />
                                </>,
                            ]}
                        />
                    </Stack>
                </>
            ),
        },
        {
            id: 'confirmConnections',
            title: 'つながり申請状況の確認方法',
            body: (
                <Typography variant={'body2'}>
                    画面右上のハンバーガーメニュー
                    <img src={hamburgerIcon} />
                    を開き、アカウントメニュー内<b>つながり申請</b>
                    を選択してください。新しいつながり申請が届いた場合、赤マークで通知されます。
                </Typography>
            ),
            children: (
                <>
                    <CHelpImage src={confirmConnect} />
                    {confirmConnectionList}
                </>
            ),
        },
        {
            id: 'tags',
            title: 'ラベルについて',
            body: (
                <Typography variant={'body2'}>
                    つながりに登録した相手との関係を、ラベルで管理できます。ラベルは相手のプロフィールページに表示されます。ラベルには、
                    <b>親族・友人・仕事</b>が用意されています。他のラベルが必要な場合は以下の手順からラベルを追加してください。
                </Typography>
            ),
            children: (
                <>
                    <CHelpImage src={tags} />
                    <Stack spacing={'0.625rem'} pt={'0.5rem'}>
                        <Typography variant={'subtitle1'}>ラベルの作成、編集、削除</Typography>
                        {tagsListItems}
                    </Stack>
                </>
            ),
        },
        {
            id: 'sharingMemories',
            title: '思い出の共有について',
            body: (
                <Typography variant={'body2'}>
                    つながりを登録すると思い出の共有ができるようになります。詳しくは
                    <CHelpCommonLink onClick={() => onLinkClick('shareMemory', 'memory', true)}>思い出の共有</CHelpCommonLink>
                    をご覧ください。
                </Typography>
            ),
        },
        {
            id: 'graveVisit',
            title: 'お墓参りについて',
            body: <Typography variant={'body2'}>お墓参りやお供えをしてお悔やみの気持ちを形にすることができます。</Typography>,
            children: (
                <Stack spacing={'0.625rem'} pt={'0.5rem'}>
                    <Typography variant={'subtitle1'}>お墓参りの方法</Typography>
                    {graveVisitItemsList}
                </Stack>
            ),
        },
        // {
        //     id: 'gift',
        //     title: 'ギフトの送り方',
        //     body: (
        //         <Typography variant={'body2'}>
        //             お悔やみの気持ちや大切な人へ日頃の感謝の気持ちを込めて、自分の没後に届くギフトを送ることができます。
        //         </Typography>
        //     ),
        //     children: <Stack spacing={'0.25rem'}>{giftItemsList}</Stack>,
        // },
    ]

    return {
        connectSectionItems,
        onLinkClick,
    }
}

export const CConnectHelp: FC<CConnectHelpProps> = ({ onLinkClick }) => {
    const { connectSectionItems } = useCConnectHelp(onLinkClick)
    return (
        <Stack spacing={'2.5rem'} mt={'2.5rem'} data-testid="c-connect-help">
            {connectSectionItems.map((it, idx) => (
                <CHelpLayout key={`connectSectionItems-${idx}`} {...it} />
            ))}
        </Stack>
    )
}
