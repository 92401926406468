import { Button, Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import { CConnectGraveCommentList } from '~/components/functional/connect/grave/CConnectGraveCommentList'
import { CGraveOfferingSpecifyPosition } from '~/components/functional/offering/CGraveOfferingSpecifyPosition'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export type ConnectListConnectUuidGravePageProps = {}

export const useConnectListConnectUuidGravePage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()
    const params = useParams()
    const connectUuid = params.connectUuid

    // initial fetch
    const { data: connect } = useQuerySuspense(
        [`/connectList/${connectUuid}/grave/connect`],
        async () => {
            if (!connectUuid) throw new Error()
            return await apiClient.clientConnectGetConnect({ parameter: { connectUuid: connectUuid } })
        },
        {
            onError: async (e) => {
                let message = 'キャンセルに失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const { data: grave } = useQuerySuspense(
        [`/connectList/${connectUuid}/grave/grave`],
        async () => {
            if (!connectUuid) throw new Error()
            return await apiClient.clientConnectGraveGetConnectGrave({ parameter: { connectUuid: connectUuid } })
        },
        {
            onError: async (e) => {
                let message = 'お墓の取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const { data: displayOrders } = useQuerySuspense(
        [`/connectList/${connectUuid}/grave/orders`],
        async () => {
            if (!connectUuid) throw new Error()
            return await apiClient.clientOfferingOrderDisplayIndex({ parameter: { connectUuid: connectUuid } })
        },
        {
            onError: async (e) => {
                let message = 'お供え物注文取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const [detailPreviews, setDetailPreviews] = useState<Schemas.ClientOfferingOrderPreviewDto[]>([])

    useEffect(() => {
        const details: Schemas.ClientOfferingOrderPreviewDto[] = []
        if (!displayOrders) {
            setDetailPreviews([])
            return
        }
        displayOrders.map((order) => {
            order.details.map((detail) => {
                details.push(detail)
            })
        })

        setDetailPreviews(details)
    }, [displayOrders])

    // お供え物の描画
    const GraveOfferingSpecifyPosition = <CGraveOfferingSpecifyPosition connectUuid={connectUuid!} details={detailPreviews} />

    const handleMoveToOfferingOrder = () => {
        navigate(`/connectList/${connectUuid}/grave/${grave!.uuid}/offering`)
    }

    const commentList = <CConnectGraveCommentList connectUuid={connectUuid} />

    return {
        connect,
        grave,
        handleMoveToOfferingOrder,
        GraveOfferingSpecifyPosition,
        commentList,
        navigate,
    }
}

export const ConnectListConnectUuidGravePage: FC<ConnectListConnectUuidGravePageProps> = () => {
    const { connect, grave, handleMoveToOfferingOrder, GraveOfferingSpecifyPosition, commentList, navigate } =
        useConnectListConnectUuidGravePage()
    return (
        <>
            {connect && (
                <DefaultLayout title="" breadcrumbList={[]} tabValue={'connectList'}>
                    <Stack alignItems={'center'} spacing={1}>
                        <Typography variant={'subtitle2'} color={'black'}>
                            {connect.sei}
                            {connect.mei}
                        </Typography>
                        <Typography variant={'subtitle2'} color={'black'}>
                            {connect.name}
                        </Typography>
                        {connect.isConnectUserDummy && (
                            <Typography variant={'body1'} color={'black'}>
                                {connect.ruby}
                            </Typography>
                        )}
                    </Stack>
                    {!grave ? (
                        <Typography sx={{ mt: 2, ml: 2 }}>お墓が登録されていません</Typography>
                    ) : (
                        <Stack sx={{ mt: 2 }} spacing={3}>
                            {GraveOfferingSpecifyPosition}

                            <Button variant="contained" fullWidth onClick={handleMoveToOfferingOrder}>
                                お供えする
                            </Button>

                            {connect?.isManageUser && (
                                <Stack spacing={2}>
                                    <Button
                                        onClick={() => navigate(`/connectList/${connect?.uuid}/grave/edit/graveName`)}
                                        variant={'contained'}
                                        fullWidth>
                                        お墓の編集
                                    </Button>
                                    <Button
                                        onClick={() => navigate(`/connectList/${connect?.uuid}/grave/edit/gravePic`)}
                                        variant={'contained'}
                                        fullWidth>
                                        お墓の写真をアップロード
                                    </Button>
                                </Stack>
                            )}

                            {commentList}
                        </Stack>
                    )}
                </DefaultLayout>
            )}
        </>
    )
}
