import { LoadingButton } from '@mui/lab'
import { Box, FormControl, FormHelperText, Stack, Typography } from '@mui/material'
import type { ChangeEvent, FC } from 'react'
import { useState } from 'react'

import { Schemas } from '~/apis/types'
import { createApiClient } from '~/utils/createApiClient'

const useCFileUpload = (completionHandler: (value?: Schemas.FileEntities[], error?: unknown) => void) => {
    const apiClient = createApiClient()
    const [uploading, setUploading] = useState(false)
    const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files

        if (!files || files.length === 0) return

        setUploading(true)

        const uploadFile = async (file: Blob) => {
            const formData = new FormData()
            formData.append('files', file)
            // @ts-expect-error
            return await apiClient.clientFileUploadUpload({ requestBody: formData })
        }

        try {
            const results = await Promise.all(Array.from(files).map(uploadFile))
            const newUploadedImages: Schemas.FileEntities[] = results.flat()
            completionHandler(newUploadedImages)
        } catch (e) {
            completionHandler(undefined, e)
        } finally {
            setUploading(false)
        }
        // イベントファイルは変更されないため、手動でリセットする必要があります。そうしないと、同じ写真を複数回アップロードできなくなります。
        event.target.value = ''
    }

    return {
        onChange,
        uploading,
    }
}
export type CFileUploadProps = {
    label: string
    error?: boolean
    helperText?: string
    completionHandler: (value?: Schemas.FileEntities[], error?: unknown) => void
    required?: boolean
    multiple?: boolean
}

export const CFileUpload: FC<CFileUploadProps> = ({ label, error, completionHandler, required, multiple }) => {
    const { onChange, uploading } = useCFileUpload(completionHandler)

    return (
        <>
            <FormControl error={error}>
                <Stack spacing={2}>
                    <Typography variant={'h6'}>{`${label}${required ? '*' : ''}`}</Typography>
                    <Box sx={{ direction: 'column', justifyContent: 'center' }}>
                        <FormHelperText>
                            <Typography variant={'caption'} color={'error'}>
                                {error ? '画像が選択されていません' : ''}
                            </Typography>
                        </FormHelperText>
                        <LoadingButton variant={'contained'} loading={uploading} fullWidth component={'label'}>
                            アップロード
                            <input hidden type={'file'} multiple={multiple} accept={'.jpeg,.jpg,.png,.gif'} onChange={onChange} />
                        </LoadingButton>
                    </Box>
                </Stack>
            </FormControl>
        </>
    )
}
