import { Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useMemo } from 'react'
import { useParams } from 'react-router'

import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useAuthState } from '~/store/auth'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useUserMobilePhoneCredentialPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const { me } = useAuthState()

    const params = useParams()
    const credential = params.credential

    const checkRegisterMobilePhone = useMemo(() => {
        return me?.mobilePhone ? '変更' : '登録'
    }, [me?.mobilePhone])

    const { data: activateMobilePhone } = useQuerySuspense(
        [`activateMobilePhone`],
        async () => {
            return await apiClient.clientUserActivateMobilePhone({
                parameter: { token: credential! },
            })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
            enabled: !!credential,
        },
    )

    return {
        activateMobilePhone,
        checkRegisterMobilePhone,
    }
}

export const UserMobilePhoneCredentialPage: FC = () => {
    const { activateMobilePhone, checkRegisterMobilePhone } = useUserMobilePhoneCredentialPage()
    return (
        <DefaultLayout title={`携帯電話番号${checkRegisterMobilePhone}確認`} breadcrumbList={[]}>
            {activateMobilePhone && activateMobilePhone.activate ? (
                <>
                    <Stack spacing={3}>
                        <Typography variant="body2" textAlign={'center'}>
                            携帯電話番号を{checkRegisterMobilePhone}しました
                            <br />ご{checkRegisterMobilePhone}した携帯電話番号でのログインになりますのでご注意下さい
                        </Typography>
                    </Stack>
                </>
            ) : (
                <>
                    <Stack spacing={3}>
                        <Typography variant="body2" textAlign={'center'}>
                            リンクが切れています
                            <br />
                            再度、ご{checkRegisterMobilePhone}をお願い致します
                        </Typography>
                    </Stack>
                </>
            )}
        </DefaultLayout>
    )
}
