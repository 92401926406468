import { Box, Button, Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router'

import { Schemas } from '~/apis/types'
import addSvg from '~/assets/image/icon/add.svg'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export type ConnectListManagementPageProps = {}

export const useConnectListManagementPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()

    const onErrorHandle = async (e: unknown) => {
        let message = '取得に失敗しました'
        if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
        await queueDialog({
            type: 'alert',
            title: 'エラーが発生しました',
            text: message,
        })
    }

    // initial fetch
    const { data: connectList } = useQuerySuspense(
        ['/connect/list'],
        async () => {
            return await apiClient.clientConnectGetConnectManagementList({})
        },
        {
            onError: onErrorHandle,
        },
    )
    const { data: relative } = useQuerySuspense(
        ['/relative/list'],
        async () => {
            return await apiClient.clientConnectGetConnectList({
                parameter: { relative: true },
            })
        },
        {
            onError: onErrorHandle,
        },
    )

    const relativesInfo = useMemo(() => {
        if (!relative?.list || !relative?.list.length) {
            return (
                <Typography variant="body1" px={'1rem'}>
                    管理している方はありません
                </Typography>
            )
        }
        return relative.list.map((i) => {
            return (
                <ProfileRow
                    key={i.uuid}
                    name={i.name}
                    profile={i.file}
                    onClick={() => navigate(`/connectList/${i.uuid}/relative`)}
                />
            )
        })
    }, [relative])

    const connectListInfo = useMemo(() => {
        if (!connectList?.length) {
            return (
                <Typography variant="body1" px={'1rem'}>
                    託されているつながりがありません
                </Typography>
            )
        }
        return connectList.map((i) => {
            return <ProfileRow key={i.uuid} profile={i.file} name={i.name} onClick={() => detailHandler(i)} />
        })
    }, [connectList])

    const detailHandler = async (connect: Schemas.ConnectEntities) => {
        connect.isConnectUserDummy ? navigate(`/connectList/${connect.uuid}/relative`) : navigate(`/connectList/${connect.uuid}`)
    }

    return {
        relativesInfo,
        connectListInfo,
        navigate,
    }
}

export const ConnectListManagementPage: FC<ConnectListManagementPageProps> = () => {
    const { connectListInfo, relativesInfo, navigate } = useConnectListManagementPage()
    return (
        <DefaultLayout breadcrumbList={[]} tabValue={'connectList'}>
            <Stack spacing={4} mt={'32px'}>
                <Stack>
                    <Typography sx={{ mt: 0.5 }} variant={'subtitle2'}>
                        あなたが管理しているお墓
                    </Typography>
                    <Stack mt={0.5} mx={'-1rem'} mb={4}>
                        {relativesInfo}
                    </Stack>
                    <Button
                        variant={'contained'}
                        startIcon={<img alt="" src={addSvg} />}
                        onClick={() => navigate('/connectList/new/edit', { state: { isDummy: true } })}>
                        お墓を追加する
                    </Button>
                </Stack>
                <Stack>
                    <Typography variant={'subtitle2'}>あなたに管理を託された方</Typography>
                    <Stack mt={0.5} mx={'-1rem'}>
                        {connectListInfo}
                    </Stack>
                </Stack>
            </Stack>
        </DefaultLayout>
    )
}

type ProfileRowType = {
    profile?: Schemas.FileEntities | null
    name: string
    onClick: () => void
}

const ProfileRow = (props: ProfileRowType) => {
    const { profile, name, onClick } = props
    return (
        <Box width={'100%'} px={'1rem'} bgcolor={'white'} onClick={() => onClick()}>
            <Box display={'flex'} gap={'10px'} borderBottom={'0.5px #C6C6C8 solid'} py={'0.8rem'}>
                <img
                    alt="profile"
                    src={mediaUrl(profile, true)}
                    width={'32px'}
                    height={'32px'}
                    style={{ borderRadius: '100px', border: '0.5px #AF985A solid' }}
                />
                <Typography variant={'body2'}>{name}</Typography>
            </Box>
        </Box>
    )
}
