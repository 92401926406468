import { useDisclosure } from '@chakra-ui/hooks'
import { TabContext, TabList } from '@mui/lab'
import { Box, Button, CircularProgress, Grid, Menu, MenuItem, MenuList, Stack, Tab, Typography } from '@mui/material'
import { useInfiniteQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { FC, useMemo, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { useNavigate, useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import addImage from '~/assets/image/memory/addImage.svg'
import noImage from '~/assets/image/noImage.png'
import { CMemoryBaseInfo } from '~/components/functional/memory/cMemoryBaseInfo/CMemoryBaseInfo'
import { CMemoryDisplayFileDialog } from '~/components/functional/memory/cMemoryDisplayFileDialog/CMemoryDisplayFileDialog'
import { CMemoryFileDialog } from '~/components/functional/memory/cMemoryFileDialog/CMemoryFileDialog'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useMemoryMemoryUuidPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const params = useParams()
    const memoryUuid = params.memoryUuid
    const navigate = useNavigate()

    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
    const menuButtonHandler = (e: HTMLElement) => {
        setMenuAnchorEl(e)
    }
    const menuCloseHandler = () => {
        setMenuAnchorEl(null)
    }
    const handleMenuItem = () => {
        if (!memoryUuid) return
        navigate(`/memory/${memoryUuid}/edit`)
    }

    const [loading, setLoading] = useState(false)
    const limit = 4
    // initial fetch
    const { data: memory, refetch: fetchMemory } = useQuerySuspense(
        [`/memory/${memoryUuid}/detail`],
        async () => {
            if (!memoryUuid) new Error()
            return await apiClient.clientMemoryGet({ parameter: { memoryUuid: memoryUuid! } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const getMemoryAsset = async (memoryUuid: string, page: number) => {
        if (!memoryUuid) new Error()
        return await apiClient.clientMemoryAssetGetList({
            parameter: {
                page,
                limit,
                memoryUuid,
            },
        })
    }

    const {
        data: assetResponse,
        refetch: fetchMemoryAsset,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery(
        [`/memory/${memoryUuid}/asset`],
        async ({ pageParam = 1 }) => await getMemoryAsset(memoryUuid!, pageParam),
        {
            getNextPageParam: (lastPage, pages) => {
                if (!lastPage.count) return undefined
                const totalPages = Math.ceil(lastPage.count / limit)

                if (totalPages > pages.length) return pages.length + 1

                return undefined
            },
            retry: false,
            refetchOnWindowFocus: false,
            suspense: true,
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const loadSpinner = useMemo(() => {
        if (assetResponse?.pages && assetResponse.pages[0].count! > 0) {
            return (
                hasNextPage && (
                    <InView
                        as={'div'}
                        width={'full'}
                        onChange={(inView) => {
                            if (inView) {
                                setLoading(true)
                                setTimeout(async () => await fetchNextPage(), 1000)
                            }
                        }}>
                        {(isFetchingNextPage || loading) && (
                            <CircularProgress sx={{ height: '48px', width: '48px' }} color={'primary'} />
                        )}
                    </InView>
                )
            )
        }
    }, [hasNextPage, isFetchingNextPage, loading, assetResponse?.pages])

    const { data: imageMaster } = useQuerySuspense(
        ['/memory/share/imageMaster'],
        async () => {
            return await apiClient.clientImageMasterShow({ parameter: { code: 'memoryEveryday' } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const deleteMemoryHandler = async () => {
        try {
            const message = '削除します。よろしいでしょうか？'
            if (!memoryUuid) return
            if (
                await queueDialog({
                    type: 'confirm',
                    title: '削除',
                    text: message,
                })
            ) {
                await apiClient.clientMemoryDelete({ parameter: { memoryUuid: memoryUuid } })
                navigate('/memory', { replace: true })
            }
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const tabHandler = (value: string) => {
        if (!memoryUuid) return
        navigate(`/memory/${memoryUuid}/${value}`, { replace: true, state: { assetCount: assetResponse?.pages[0].count } })
    }

    // 編集モーダルの表示状態
    const { isOpen: editDialogIsOpen, onOpen: updateModalOnOpen, onClose: editDialogOnClose } = useDisclosure()
    const addButtonHandler = () => {
        updateModalOnOpen()
        menuCloseHandler()
    }
    const editDialogSubmitHandler = async (dto: { fileUuids: string[] }) => {
        try {
            const promises = dto.fileUuids.map(
                async (i) =>
                    await apiClient.clientMemoryAssetCreate({
                        parameter: { memoryUuid: memoryUuid! },
                        requestBody: { fileUuid: i },
                    }),
            )
            await Promise.all(promises)
            // else await apiClient.clientMemoryUpdate({ parameter: { memoryUuid }, requestBody: dto })

            await fetchMemory()
            await fetchMemoryAsset()
            editDialogOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }
    const memoryFileDialog = (
        <CMemoryFileDialog isOpen={editDialogIsOpen} onClose={editDialogOnClose} onSubmit={editDialogSubmitHandler} />
    )

    const [displayTarget, setDisplayTarget] = useState<Schemas.MemoryAssetEntities>()
    const { isOpen: displayDialogIsOpen, onOpen: updateDisplayModalOnOpen, onClose: displayDialogOnClose } = useDisclosure()
    const displayFileHandler = (asset: Schemas.MemoryAssetEntities) => {
        if (!asset.file) return
        setDisplayTarget(asset)
        updateDisplayModalOnOpen()
    }
    const deleteHandler = async (asset: Schemas.MemoryAssetEntities) => {
        try {
            const message = '削除すると元に戻すことはできません。この画像を削除しますか？削除します。よろしいでしょうか？'
            if (!asset) return
            if (
                await queueDialog({
                    type: 'confirm',
                    title: '画像を削除',
                    text: message,
                })
            ) {
                await apiClient.clientMemoryAssetDelete({ parameter: { memoryUuid: memoryUuid!, assetUuid: asset.uuid } })
                await fetchMemoryAsset()
                displayDialogOnClose()
            }
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }
    const getYoutubeThumbnailUrl = (youtubeUrl: string) => {
        try {
            const url = new URL(youtubeUrl)
            const videoId = url.hostname.includes('youtu.be') ? url.pathname.slice(1) : new URLSearchParams(url.search).get('v')
            return videoId ? `https://img.youtube.com/vi/${videoId}/sddefault.jpg` : null
        } catch {
            return null
        }
    }

    const assetList = useMemo(() => {
        return assetResponse?.pages?.map((page) => {
            return page.list?.map((asset, index) => (
                <Grid item xs={4} key={index}>
                    <Box
                        component={'img'}
                        src={asset.url ? getYoutubeThumbnailUrl(asset.url) ?? noImage : mediaUrl(asset.file)}
                        loading="lazy"
                        alt={asset.url ? asset.title ?? '' : asset.file?.filename}
                        sx={{
                            padding: 0.5,
                            width: { xs: '30vmin', sm: '30vmin' },
                            maxWidth: '240px',
                            height: { xs: '30vmin', sm: '30vmin' },
                            maxHeight: '240px',
                            objectPosition: 'center',
                            objectFit: 'cover',
                            borderRadius: '10px',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            if (asset.url) window.open(asset.url, '_blank', 'noopener,noreferrer')
                            else displayFileHandler(asset)
                        }}
                    />
                </Grid>
            ))
        })
    }, [assetResponse?.pages, assetResponse])

    const cMemoryDisplayFileDialog = (
        <CMemoryDisplayFileDialog
            isOpen={displayDialogIsOpen}
            onClose={displayDialogOnClose}
            deleteHandler={deleteHandler}
            entity={displayTarget}
        />
    )

    return {
        menuAnchorEl,
        menuButtonHandler,
        menuCloseHandler,
        handleMenuItem,
        loadSpinner,
        memory,
        imageMaster,
        assetResponse,
        deleteMemoryHandler,
        tabHandler,
        assetList,
        addButtonHandler,
        memoryFileDialog,
        getYoutubeThumbnailUrl,
        displayFileHandler,
        cMemoryDisplayFileDialog,
    }
}

export const MemoryMemoryUuidPage: FC = () => {
    const {
        menuAnchorEl,
        menuButtonHandler,
        menuCloseHandler,
        handleMenuItem,
        memory,
        imageMaster,
        loadSpinner,
        deleteMemoryHandler,
        tabHandler,
        assetList,
        addButtonHandler,
        memoryFileDialog,
        cMemoryDisplayFileDialog,
        assetResponse,
    } = useMemoryMemoryUuidPage()
    return (
        <>
            <DefaultLayout title="" breadcrumbList={[]} tabValue={'memory'}>
                <CMemoryBaseInfo memory={memory!} menuButtonHandler={menuButtonHandler} imageMaster={imageMaster} />
                <Stack spacing={2}>
                    <TabContext value={''}>
                        <TabList centered sx={{ borderBottom: '1px solid #EFEAE6' }}>
                            <Tab
                                label={
                                    <Typography variant={'body1'}>{`メディア (${assetResponse?.pages?.[0].count})`}</Typography>
                                }
                                value={''}
                                onClick={() => tabHandler('')}
                            />
                            <Tab
                                label={<Typography variant={'body1'}>{`コメント (${memory?.commentCount})`}</Typography>}
                                value={'comment'}
                                onClick={() => tabHandler('comment')}
                            />
                            <Tab
                                label={<Typography variant={'body1'}>{`メンバー (${memory!.connects?.length + 1})`}</Typography>}
                                value={'member'}
                                onClick={() => tabHandler('member')}
                            />
                        </TabList>
                    </TabContext>
                    <Stack direction={'column'} spacing={1} alignItems={'center'}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Box
                                    component={'img'}
                                    src={addImage}
                                    loading="lazy"
                                    alt="add"
                                    onClick={() => addButtonHandler()}
                                    sx={{
                                        padding: 0.5,
                                        width: { xs: '30vmin', sm: '30vmin' },
                                        maxWidth: '240px',
                                        height: { xs: '30vmin', sm: '30vmin' },
                                        maxHeight: '240px',
                                        objectPosition: 'center',
                                        objectFit: 'cover',
                                        borderRadius: '10px',
                                        cursor: 'pointer',
                                    }}
                                />
                            </Grid>
                            {assetList}
                        </Grid>
                        {loadSpinner}
                    </Stack>
                </Stack>
            </DefaultLayout>
            {memoryFileDialog}
            {cMemoryDisplayFileDialog}
            <Menu
                open={!!menuAnchorEl}
                onClose={menuCloseHandler}
                anchorEl={menuAnchorEl}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        boxShadow: 1,
                        px: 1,
                        placement: 'bottom-end',
                    },
                }}>
                <Typography variant={'body2'} textAlign={'center'} sx={{ borderBottom: 1, pb: 1 }}>
                    操作を選択してください
                </Typography>
                <MenuList dense>
                    <MenuItem divider sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => addButtonHandler()}>
                        <Button variant={'text'} color={'primary'}>
                            画像を追加
                        </Button>
                    </MenuItem>
                    <MenuItem divider sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => handleMenuItem()}>
                        <Button variant={'text'} color={'primary'}>
                            思い出を編集
                        </Button>
                    </MenuItem>
                    <MenuItem sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => deleteMemoryHandler()}>
                        <Button variant={'text'} color={'error'}>
                            思い出を削除
                        </Button>
                    </MenuItem>
                </MenuList>
            </Menu>
        </>
    )
}
