import { LoadingButton } from '@mui/lab'
import {
    Button,
    Checkbox,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
} from '@mui/material'
import { FC, useEffect } from 'react'

import { Schemas } from '~/apis/types'

export const useCMemberDialog = (props: CMemberDialogProps) => {
    const { setConnectUuids, uuids, isOpen } = props
    const handleSelected = (uuid: string) => {
        const newArr = uuids.includes(uuid) ? uuids.filter((i) => i !== uuid) : [uuid, ...uuids]
        setConnectUuids(newArr)
    }
    useEffect(() => {
        setConnectUuids(uuids)
    }, [isOpen, uuids])
    return {
        handleSelected,
    }
}

export type CMemberDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (uuids: string[]) => void
    setConnectUuids: (uuids: string[]) => void
    connectList?: Schemas.ClientMemoryConnectListDto
    uuids: string[]
    title: string
}

export const CMemberDialog: FC<CMemberDialogProps> = (props) => {
    const { handleSelected } = useCMemberDialog(props)
    const { isOpen, onClose, title, connectList, onSubmit, uuids } = props
    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle textAlign={'center'} sx={{ fontWeight: 700 }}>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {connectList?.memoryOwner && (
                            <ListItem disablePadding>
                                <ListItemButton role={undefined} dense disabled={true}>
                                    <ListItemIcon>
                                        <Checkbox edge="start" checked={true} tabIndex={-1} disableRipple />
                                    </ListItemIcon>
                                    <ListItemText primary={connectList.memoryOwner.name} />
                                </ListItemButton>
                            </ListItem>
                        )}
                        {connectList?.connects.length
                            ? connectList.connects.map((connect) => (
                                  <ListItem key={connect.uuid} disablePadding>
                                      <ListItemButton
                                          role={undefined}
                                          onClick={() => handleSelected(connect.uuid)}
                                          dense
                                          disabled={!connect.isCurrentUserConnect}>
                                          <ListItemIcon>
                                              <Checkbox
                                                  edge="start"
                                                  checked={!!uuids.find((c) => c === connect.uuid)}
                                                  tabIndex={-1}
                                                  disableRipple
                                              />
                                          </ListItemIcon>
                                          <ListItemText
                                              primary={connect.name}
                                              secondary={`${connect.sei ?? ''} ${connect.mei ?? ''}`}
                                          />
                                      </ListItemButton>
                                  </ListItem>
                              ))
                            : 'つながり人がありません。'}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Container maxWidth={'xs'}>
                        <Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
                            <Button variant="outlined" fullWidth onClick={onClose}>
                                キャンセル
                            </Button>
                            <LoadingButton
                                disabled={connectList!.connects.length < 1}
                                variant={'contained'}
                                fullWidth
                                onClick={() => onSubmit(uuids)}>
                                決定
                            </LoadingButton>
                        </Stack>
                    </Container>
                </DialogActions>
            </Dialog>
        </>
    )
}
