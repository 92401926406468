import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Container, Grid, Link, Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import decorated from '~/assets/image/decorated.svg'
import facebookLogin from '~/assets/image/top/facebook_login.png'
import lineHover from '~/assets/image/top/line_hover.png'
import lineLogin from '~/assets/image/top/line_login.png'
import linePress from '~/assets/image/top/line_press.png'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import {
    ClientUserEmailTokenRequestDtoSchema,
    clientUserEmailTokenRequestDtoSchema,
    ClientUserMobilePhoneTokenRequestDtoSchema,
    clientUserMobilePhoneTokenRequestDtoSchema,
} from '~/types/zodScheme'
import { createApiClient } from '~/utils/createApiClient'

export const useUserAccountIndexPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
    } = useForm<ClientUserMobilePhoneTokenRequestDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientUserMobilePhoneTokenRequestDtoSchema),
    })

    useEffect(() => {
        reset({
            mobilePhone: '',
            inviteCode: null,
        })
    }, [isSubmitting])

    const {
        register: registerEmail,
        handleSubmit: handleSubmitEmail,
        formState: { errors: errorsEmail, isSubmitting: isSubmittingEmail, isValid: isValidEmail },
        reset: resetEmail,
    } = useForm<ClientUserEmailTokenRequestDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientUserEmailTokenRequestDtoSchema),
    })

    useEffect(() => {
        resetEmail({
            email: '',
            inviteCode: null,
        })
    }, [isSubmittingEmail])

    const sendHandler = async (dto: Schemas.ClientUserMobilePhoneTokenRequestDto) => {
        try {
            await apiClient.clientUserCreateMobilePhoneToken({ requestBody: dto })
            await queueDialog({
                type: 'alert',
                title: 'メッセージを送信',
                text: '入力された携帯電話へメッセージを送信しました',
            })
        } catch (e) {
            let message = '送信に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const sendHandlerEmail = async (dto: Schemas.ClientUserEmailTokenRequestDto) => {
        try {
            await apiClient.clientUserCreateEmailToken({ requestBody: dto })
            await queueDialog({
                type: 'alert',
                title: 'メールを送信',
                text: '入力されたメールアドレスへメールを送信しました',
            })
        } catch (e) {
            let message = '送信に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const [lineIcon, setLineIcon] = useState<string>(lineLogin)
    const signInLine = async () => {
        const url = await apiClient.clientLineAuthLoginLine()
        window.location.href = url
    }

    const signInFacebook = async () => {
        const url = await apiClient.clientFacebookAuthFacebookLogin()
        window.location.href = url
    }

    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        sendHandler,
        sendHandlerEmail,
        registerEmail,
        handleSubmitEmail,
        errorsEmail,
        isValidEmail,
        isSubmittingEmail,
        signInLine,
        lineIcon,
        setLineIcon,
        signInFacebook,
    }
}

export const UserAccountIndexPage: FC = () => {
    const {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        sendHandler,
        sendHandlerEmail,
        registerEmail,
        handleSubmitEmail,
        errorsEmail,
        isValidEmail,
        isSubmittingEmail,
        signInLine,
        lineIcon,
        setLineIcon,
        signInFacebook,
    } = useUserAccountIndexPage()
    return (
        <DefaultLayout title={'新規会員登録'} breadcrumbList={[]} isTab={false}>
            <Container maxWidth={'sm'} sx={{ my: 3, fontSize: 'large', px: 1 }}>
                <Stack spacing={4}>
                    <Box>
                        <Typography variant="body2">
                            これ終をご利用いただくためには会員登録が必要です。（会員登録は無料です）
                        </Typography>
                        <Typography mt={2} variant="body2">
                            これ終の
                            <Link color={'#77618B'} fontWeight={'bold'} href="/terms" target="_blank">
                                サービス利用規約
                            </Link>
                            と
                            <Link color={'#77618B'} fontWeight={'bold'} href="/privacy" target="_blank">
                                プライバシーポリシー
                            </Link>
                            に同意した上で会員登録をお願いします。
                        </Typography>
                    </Box>

                    <Grid container justifyContent={'center'} direction={'column'}>
                        <Grid item xs={12} sm={6} py={1}>
                            <Button
                                variant={'outlined'}
                                fullWidth
                                startIcon={<img src={lineIcon} height={'24px'} />}
                                onClick={() => signInLine()}
                                onMouseEnter={() => setLineIcon(lineHover)}
                                onMouseLeave={() => setLineIcon(lineLogin)}
                                onMouseDown={() => setLineIcon(linePress)}
                                onMouseUp={() => setLineIcon(lineHover)}>
                                <Typography variant="body2" fontWeight={'bold'} fontFamily={'Noto Sans JP'}>
                                    LINE で会員登録
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} py={1}>
                            <Button
                                variant={'outlined'}
                                fullWidth
                                startIcon={<img src={facebookLogin} height={'24px'} />}
                                onClick={() => signInFacebook()}>
                                <Typography variant="body2" fontWeight={'bold'} fontFamily={'Noto Sans JP'}>
                                    Facebook で会員登録
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                    <img src={decorated} />

                    <Stack spacing={0.5}>
                        <CInputLabel label="携帯電話番号で登録" />
                        <Typography variant="caption">ハイフン(-)は除いて入力してください</Typography>
                        <TextField
                            {...register('mobilePhone')}
                            placeholder={'08000000000'}
                            type={'text'}
                            error={!!errors.mobilePhone}
                            helperText={errors.mobilePhone?.message}
                            variant={'outlined'}
                        />

                        <Typography variant="caption" fontSize={'medium'}>
                            入力していただいた携帯電話番号に招待が送信されます。
                        </Typography>
                    </Stack>
                    <LoadingButton
                        variant={'contained'}
                        disabled={!isValid}
                        loading={isSubmitting}
                        sx={{ height: '3rem' }}
                        onClick={handleSubmit(sendHandler)}>
                        送信
                    </LoadingButton>
                </Stack>
                <Stack my={3} spacing={2}>
                    <img src={decorated} style={{ marginBottom: '1.25rem' }} />
                    <Stack spacing={1}>
                        <CInputLabel label="メールアドレスで登録" />
                        <TextField
                            {...registerEmail('email')}
                            placeholder={'login@koreshu.jp'}
                            error={!!errorsEmail.email}
                            helperText={errorsEmail.email?.message}
                            type={'text'}
                            variant={'outlined'}
                        />
                        <Typography variant="caption" fontSize={'medium'}>
                            入力していただいたメールアドレス宛に招待が送信されます。
                        </Typography>
                    </Stack>
                    <LoadingButton
                        variant={'contained'}
                        disabled={!isValidEmail}
                        loading={isSubmittingEmail}
                        sx={{ height: '3rem' }}
                        onClick={handleSubmitEmail(sendHandlerEmail)}>
                        送信
                    </LoadingButton>
                </Stack>
            </Container>
            <Container maxWidth={'sm'} sx={{ my: 3, fontSize: 'large' }}>
                <Stack spacing={3}>
                    <img src={decorated} />
                    <Stack sx={{ alignItems: 'center' }}>
                        <Typography variant="button" fontSize={'large'}>
                            アカウントをお持ちの場合は
                        </Typography>
                        <Link color={'#77618B'} href="/login">
                            ログイン
                        </Link>
                    </Stack>
                    <Stack spacing={4} sx={{ alignItems: 'center' }}>
                        <img src={decorated} width={'100%'} />
                        <Link color={'#77618B'} href="/inquiry">
                            お問合せ
                        </Link>
                    </Stack>
                </Stack>
            </Container>
        </DefaultLayout>
    )
}
