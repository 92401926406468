import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Avatar, Button, Stack, TextField, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'

import { Schemas } from '~/apis/types'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { CIconUpload } from '~/components/functional/cIconUpload/CIconUpload'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { ClientUserUpdateDtoSchema, clientUserUpdateDtoSchema } from '~/types/zodScheme'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useUserEdit = () => {
    const navigate = useNavigate()
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()

    // initial fetch
    const { data: me } = useQuerySuspense(
        ['editUser'],
        async () => {
            return await apiClient.clientAuthUserMe()
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )
    const { data: address } = useQuery(
        ['editAddress'],
        async () => {
            const res = await apiClient.clientUserAddressGetMain({})

            return res.length > 0 ? res[0] : null
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
            suspense: true,
        },
    )

    const [image, setImage] = useState<Schemas.FileEntities | null>(me?.file || null)
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
        setValue,
        control,
    } = useForm<ClientUserUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientUserUpdateDtoSchema),
    })

    // 初期化
    useEffect(() => {
        reset({
            name: me?.name || '',
            birthday: me?.birthday || null,

            sei: address?.sei || '',
            mei: address?.mei || '',

            postalCode: address?.postalCode || '',
            pref: address?.pref || '',
            city: address?.city || '',
            address: address?.address || '',
            building: address?.building || null,
            tel: address?.tel || null,

            fileUuid: me?.file?.uuid || null,
        })
        setImage(me?.file || null)
        setPostalCode(address?.postalCode || '')
    }, [me])

    const editUserSubmitHandler = async (dto: Schemas.ClientUserUpdateDto) => {
        try {
            await apiClient.clientUserUpdate({ requestBody: dto })
            navigate('/user', { replace: true })
        } catch (e) {
            let message = '取得に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const handleUploadFile = (value?: Schemas.FileEntities) => {
        if (value?.uuid) {
            setValue('fileUuid', value.uuid)
            setImage(value)
        }
    }
    const handleDeleteFile = () => {
        setValue('fileUuid', null)
        setImage(null)
    }

    const [findZipLoading, setFindZipLoading] = useState(false)
    const [postalCode, setPostalCode] = useState(address?.postalCode || '')
    const getZipCodeAddress = async () => {
        if (!postalCode || postalCode === '') {
            await queueDialog({
                type: 'alert',
                title: '郵便番号を入力してください',
                text: '',
            })
            return
        }
        setFindZipLoading(true)
        try {
            const result: Schemas.ClientZipCodeDto[] = await apiClient.clientCommonGetZipCodeAddress({
                parameter: { zipCode: postalCode },
            })
            if (result.length > 0) {
                setValue('pref', result[0].prefecture_name)
                setValue('city', result[0].city_name)
                setValue('address', result[0].town_name)
            } else {
                await queueDialog({
                    type: 'alert',
                    title: '一致する郵便番号が見つかりませんでした',
                    text: '',
                })
            }
        } catch (e) {
            let message = '保存に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        } finally {
            setFindZipLoading(false)
        }
    }

    return {
        navigate,
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        control,
        image,
        editUserSubmitHandler,
        handleUploadFile,
        handleDeleteFile,

        findZipLoading,
        getZipCodeAddress,
        postalCode,
        setPostalCode,
    }
}

export const UserEditPage: FC = () => {
    const {
        navigate,
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        image,
        editUserSubmitHandler,
        handleUploadFile,
        handleDeleteFile,

        findZipLoading,
        getZipCodeAddress,
        postalCode,
        setPostalCode,
    } = useUserEdit()

    return (
        <>
            <DefaultLayout title={''} breadcrumbList={[]} isTab={false}>
                <Stack spacing={2}>
                    <Stack direction={'column'} alignItems={'center'}>
                        <Avatar alt={''} src={mediaUrl(image, true)} sx={{ width: 152, height: 152 }} />
                        {image ? (
                            <Button onClick={() => handleDeleteFile()}>削除</Button>
                        ) : (
                            <CIconUpload
                                completionHandler={handleUploadFile}
                                label={''}
                                error={!!errors.fileUuid}
                                helperText={errors.fileUuid?.message}
                            />
                        )}
                    </Stack>

                    {/* <Typography variant="body2">
                        プロフィールを登録すると、つながっている人からギフトを受け取ることができます。
                    </Typography> */}

                    <Stack spacing={0.5}>
                        <CInputLabel label={'ニックネーム'} required />
                        <TextField
                            id={'name'}
                            required={true}
                            {...register('name')}
                            placeholder={'ニックネーム'}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                            variant={'outlined'}
                        />
                    </Stack>
                    <Stack spacing={0.5}>
                        <CInputLabel label={'性'} required />
                        <TextField
                            id={'sei'}
                            {...register('sei')}
                            placeholder={'性'}
                            error={!!errors.sei}
                            helperText={errors.sei?.message}
                            variant={'outlined'}
                        />
                    </Stack>
                    <Stack spacing={0.5}>
                        <CInputLabel label={'名'} required />
                        <TextField
                            id={'mei'}
                            {...register('mei')}
                            placeholder={'名'}
                            error={!!errors.mei}
                            helperText={errors.mei?.message}
                            variant={'outlined'}
                        />
                        <Typography variant={'caption'}>※ 本名を入力してください</Typography>
                    </Stack>
                    <Stack spacing={1}>
                        <Stack spacing={0.5}>
                            <CInputLabel label={'郵便番号'} />
                            <Typography variant={'caption'}>※ ハイフン(-)は除いて入力してください</Typography>
                            <Stack direction={'row'} justifyContent={'start'} width={'100%'} spacing={1}>
                                <TextField
                                    id={'postalCode'}
                                    {...register('postalCode')}
                                    placeholder={'郵便番号'}
                                    error={!!errors.postalCode}
                                    helperText={errors.postalCode?.message}
                                    variant={'outlined'}
                                    sx={{ backgroundColor: 'white', width: '50%' }}
                                    value={postalCode}
                                    onChange={(e) => setPostalCode(e.target.value)}
                                />
                                <LoadingButton
                                    variant={'contained'}
                                    loading={findZipLoading}
                                    onClick={getZipCodeAddress}
                                    sx={{ width: '160px', p: 0 }}>
                                    郵便番号検索
                                </LoadingButton>
                            </Stack>
                        </Stack>
                        <Stack spacing={0.5}>
                            <CInputLabel label={'都道府県'} />
                            <TextField
                                id={'pref'}
                                {...register('pref')}
                                placeholder={'都道府県'}
                                error={!!errors.pref}
                                helperText={errors.pref?.message}
                                variant={'outlined'}
                            />
                        </Stack>
                        <Stack spacing={0.5}>
                            <CInputLabel label={'市区町村'} />
                            <TextField
                                id={'city'}
                                {...register('city')}
                                placeholder={'市区町村'}
                                error={!!errors.city}
                                helperText={errors.city?.message}
                                variant={'outlined'}
                            />
                        </Stack>
                        <Stack spacing={0.5}>
                            <CInputLabel label={'番地・その他'} />
                            <TextField
                                id={'address'}
                                {...register('address')}
                                placeholder={'番地・その他'}
                                error={!!errors.address}
                                helperText={errors.address?.message}
                                variant={'outlined'}
                            />
                        </Stack>
                        <Stack spacing={0.5}>
                            <CInputLabel label={'ビル名・部屋番号'} />
                            <TextField
                                id={'building'}
                                {...register('building')}
                                placeholder={'ビル名・部屋番号'}
                                error={!!errors.building}
                                helperText={errors.building?.message}
                                variant={'outlined'}
                            />
                        </Stack>
                        <Stack spacing={0.5}>
                            <CInputLabel label={'電話番号'} />
                            <Typography variant={'caption'}>※ ハイフン(-)は除いて入力してください</Typography>
                            <TextField
                                id={'tel'}
                                {...register('tel')}
                                placeholder={'電話番号'}
                                error={!!errors.tel}
                                helperText={errors.tel?.message}
                                variant={'outlined'}
                            />
                        </Stack>
                    </Stack>

                    <Stack spacing={2}>
                        <LoadingButton
                            variant={'contained'}
                            loading={isSubmitting}
                            disabled={!isValid}
                            onClick={handleSubmit(editUserSubmitHandler)}>
                            登録
                        </LoadingButton>
                        <Button variant={'outlined'} onClick={() => navigate('/user', { replace: true })}>
                            キャンセル
                        </Button>
                    </Stack>
                </Stack>
            </DefaultLayout>
        </>
    )
}
