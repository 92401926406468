import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { type FC, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'

import { Schemas } from '~/apis/types'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { GraveOfferingOrderCommentPage } from '~/pages/connectList/[connectUuid]/grave/[graveUuid]/offering/comment'
import { ClientRelativeGraveCreateDtoSchema, clientRelativeGraveCreateDtoSchema } from '~/types/zodScheme'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export type RelativeGraveEditPageViewProps = {}

const useRelativeGraveEditNamePage = () => {
    const { connectUuid } = useParams()

    const apiClient = createApiClient()

    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()

    const onErrorHandle = async (e: unknown) => {
        let message = '取得に失敗しました'
        if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
        await queueDialog({
            type: 'alert',
            title: 'エラーが発生しました',
            text: message,
        })
    }
    const { data: imageMasters } = useQuerySuspense(
        [`/grave/master`],
        async () => {
            return await apiClient.clientImageMasterIndex()
        },
        {
            onError: onErrorHandle,
        },
    )
    const { data: grave } = useQuerySuspense(
        ['connectGraveChange', connectUuid],
        async () => {
            return await apiClient.clientConnectGraveGetConnectGrave({ parameter: { connectUuid: connectUuid ?? '' } })
        },
        { onError: onErrorHandle },
    )
    const {
        register,
        getValues,
        setValue,
        reset,
        formState: { isValid },
    } = useForm<ClientRelativeGraveCreateDtoSchema>({
        resolver: zodResolver(clientRelativeGraveCreateDtoSchema),
        mode: 'all',
        defaultValues: { graveFile: '' },
    })
    const getMasterToImage = (): Schemas.FileEntities | undefined => {
        if (imageMasters) {
            if (!grave?.vertical) {
                const yoko = imageMasters.find((master: Schemas.ImageMasterEntities) => master.code === 'graveYoko')
                return yoko?.file
            } else {
                const tate = imageMasters.find((master: Schemas.ImageMasterEntities) => master.code === 'graveTate')
                return tate?.file
            }
        }
        return undefined
    }
    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null)
    const [context, setContext] = useState<CanvasRenderingContext2D | null>(null)

    const getContext = () => {
        const tempCanvas = canvasRef.current as HTMLCanvasElement | null
        if (!tempCanvas) return
        setCanvas(tempCanvas)
        const tempContext = tempCanvas.getContext('2d')
        if (!tempContext) return
        tempContext.imageSmoothingEnabled = false
        setContext(tempContext)
    }

    const handleChangeCanvas = (options?: { posthumousName?: string; denomination?: string }) => {
        if (!context) getContext()

        let posthumousName = getValues('posthumousName') || ''
        if (typeof options !== 'undefined' && typeof options.posthumousName !== 'undefined')
            posthumousName = options.posthumousName
        let denomination = getValues('denomination') || ''
        if (typeof options !== 'undefined' && typeof options.denomination !== 'undefined') denomination = options.denomination

        if (context !== null) {
            const img = new Image()
            img.src = mediaUrl(getMasterToImage())
            img.crossOrigin = 'anonymous'
            img.onload = () => {
                context.beginPath()
                context.fillStyle = '#fafafa'
                context.fillRect(0, 0, canvas!.width, canvas!.height)
                context.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas!.width, canvas!.height)

                if (!posthumousName && !denomination) return

                const centerWidthPoint = 327 / 2
                if (!grave?.vertical) {
                    // 横書き
                    if (posthumousName && !denomination) {
                        context.textAlign = 'center'
                        context.fillStyle = '#ffffff'
                        context.font = 'bold 18px serif'
                        context.fillText(posthumousName || '', centerWidthPoint, 163 + 18)
                    } else {
                        context.fillStyle = '#ffffff'
                        context.textAlign = 'center'
                        context.font = 'bold 18px serif'
                        context.fillText(posthumousName || '', centerWidthPoint, 145 + 18)
                        context.font = `bold 12px serif`
                        context.fillText(denomination || '', centerWidthPoint, 182 + 12)
                    }
                } else {
                    // 縦書き
                    context.textAlign = 'left'
                    context.fillStyle = '#333333'
                    const mainTitleFontSize = 18
                    const subTitleFontSize = 12
                    const subTitleFontSizeOver15 = 11
                    if (posthumousName && !denomination) {
                        context.font = `bold ${mainTitleFontSize}px serif`
                        posthumousName.split('').map((char, index) => {
                            context.fillText(
                                char,
                                centerWidthPoint - mainTitleFontSize / 2,
                                index * mainTitleFontSize * 1.2 + mainTitleFontSize + 57,
                            )
                        })
                    } else {
                        context.font = `bold ${mainTitleFontSize}px serif`
                        posthumousName.split('').map((char, index) => {
                            context.fillText(char, 163, index * mainTitleFontSize * 1.1 + mainTitleFontSize + 57)
                        })

                        if (denomination.length <= 18) {
                            context.font = `bold ${subTitleFontSize}px serif`
                            denomination.split('').map((char, index) => {
                                context.fillText(char, 140, index * subTitleFontSize * 1.1 + subTitleFontSize + 62)
                            })
                        } else {
                            context.font = `bold ${subTitleFontSizeOver15}px serif`
                            denomination.split('').map((char, index) => {
                                context.fillText(char, 140, index * subTitleFontSizeOver15 * 1.1 + subTitleFontSizeOver15 + 62)
                            })
                        }
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (grave) {
            reset({
                denomination: grave.denomination ?? '',
                posthumousName: grave.posthumousName ?? '',
                graveFile: grave.file?.uuid ?? undefined,
            })
        }
    }, [grave])

    // canvas 保存
    const handlerSaveCanvas = async () => {
        if (!canvas || !GraveOfferingOrderCommentPage) return
        return canvas.toBlob(
            async (blob) => {
                if (blob) {
                    const formData = new FormData()
                    formData.append('files', blob)
                    const uploadedFiles = await apiClient
                        // @ts-expect-error
                        .clientFileUploadUpload({ requestBody: formData })
                    setValue('graveFile', uploadedFiles[0].uuid, { shouldValidate: true })
                    return await apiClient
                        .clientConnectUpdateRelativeGrave({
                            parameter: { connectUuid: connectUuid ?? '' },
                            requestBody: {
                                ...grave,
                                name: grave?.name ?? '',
                                posthumousName: getValues('posthumousName'),
                                denomination: grave?.denomination ?? '',
                                fileUuid: getValues('graveFile') ?? '',
                            },
                        })
                        .then(() => {
                            navigate(`/connectList/${connectUuid}/grave`, { replace: true })
                        })
                        .catch(onErrorHandle)
                }
            },
            'image/png',
            1,
        )
    }
    useEffect(() => {
        if (imageMasters) handleChangeCanvas()
    }, [context, imageMasters, grave])
    return { handleChangeCanvas, handlerSaveCanvas, register, canvasRef, getValues, connectUuid, navigate, isValid }
}

export const RelativeGraveEditPageView: FC<RelativeGraveEditPageViewProps> = () => {
    const { register, connectUuid, canvasRef, handleChangeCanvas, handlerSaveCanvas, getValues, navigate, isValid } =
        useRelativeGraveEditNamePage()
    return (
        <DefaultLayout title={'名前の登録'} breadcrumbList={[]}>
            <Stack alignItems={'center'}>
                <Typography variant={'body2'}>お墓に彫る名前を編集できます</Typography>
                <Stack spacing={0.5} width={'100%'} mt={'1.5rem'}>
                    <CInputLabel label={'氏名'} required />
                    <Typography variant={'body1'}>全角10文字以内</Typography>
                    <TextField
                        id={'name'}
                        required={true}
                        placeholder={'氏名'}
                        variant={'outlined'}
                        {...register('posthumousName')}
                    />
                    <Typography variant={'body1'}>※ 絵文字や特殊文字は使えません</Typography>
                </Stack>
                <Box mt={'12px'} width={'100%'}>
                    <Button
                        onClick={() => handleChangeCanvas({ posthumousName: getValues('posthumousName') })}
                        fullWidth
                        variant={'contained'}>
                        反映する
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: '40px' }}>
                    <canvas
                        ref={canvasRef}
                        width="327"
                        height="400"
                        style={{
                            width: '327px',
                            height: '400px',
                            border: `1px solid #cccccc`,
                            backgroundColor: '#eee',
                        }}
                    />
                </Box>
                <Box display={'flex'} gap={'1rem'} width={'100%'} mt={'20px'}>
                    <Button
                        onClick={() => navigate(`/connectList/${connectUuid}/grave`, { replace: true })}
                        variant={'outlined'}
                        fullWidth>
                        キャンセル
                    </Button>
                    <Button disabled={!isValid} onClick={() => handlerSaveCanvas()} variant={'contained'} fullWidth>
                        この内容で保存
                    </Button>
                </Box>
            </Stack>
        </DefaultLayout>
    )
}

export const RelativeGraveEditNamePage: FC = () => {
    return <RelativeGraveEditPageView />
}

export default RelativeGraveEditNamePage
