import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, Link, Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'
import z from 'zod'

import { Schemas } from '~/apis/types'
import addIcon from '~/assets/image/icon/add.svg'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { CMemoryUpload } from '~/components/functional/cMemoryUpload/CMemoryUpload'
import { CPersonalHistoryDisplayFileDialog } from '~/components/functional/CPersonalHistoryFileDialog/CPersonalHistoryFileDialog'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { ClientMemoryCreateDtoSchema, clientMemoryCreateDtoSchema } from '~/types/zodScheme'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export type YoutubeURL = {
    url: string
    title: string
}

export const useMemoryMemoryUuidEditPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const params = useParams()
    const memoryUuid = params.memoryUuid
    const navigate = useNavigate()
    const [uploadedImages, setUploadedImages] = useState<Schemas.FileEntities[]>([])
    const [links, setLinks] = useState<YoutubeURL[]>([])
    const [dialogOpen, setDialogOpen] = useState(false)

    // initial fetch
    const { data: memory } = useQuerySuspense(
        [`/memory/${memoryUuid}/detail`],
        async () => {
            if (!memoryUuid) new Error()
            if (memoryUuid === 'new') return { uuid: '', connects: [], publish: false } as unknown as Schemas.MemoryEntities
            return await apiClient.clientMemoryGet({ parameter: { memoryUuid: memoryUuid! } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const { data: memoryAssetList } = useQuerySuspense([`/memory/create/edit/${memoryUuid}/asset`], async () => {
        if (memoryUuid === 'new') return { list: [], count: 0 }

        return await apiClient.clientMemoryAssetGetList({ parameter: { memoryUuid: memoryUuid ?? '' } })
    })

    useEffect(() => {
        const youtubeLinks = memoryAssetList?.list
            ?.filter((i) => i.url)
            .map((i) => ({ title: i.title ?? '', url: i.url ?? '' }))
            .filter(Boolean) as YoutubeURL[]
        const uploadedImages = memoryAssetList?.list
            ?.filter((i) => i.file)
            .map((i) => i.file!)
            .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
            .filter(Boolean) as Schemas.FileEntities[]
        setLinks(youtubeLinks)
        setUploadedImages(uploadedImages)
    }, [memoryAssetList])

    const checkYoutubeUrlPattern = (value: string) => {
        const youtubeUrlPattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be|m\.youtube\.com)\/.+$/
        return youtubeUrlPattern.test(value)
    }
    const [youtubeLink, setYoutubeLink] = useState<string>('')
    const [youtubeLinkMessage, setYoutubeLinkMessage] = useState<string>('')
    const [youtubeTitle, setYoutubeTitle] = useState<string>('')
    const [youtubeTitleMessage, setYoutubeTitleMessage] = useState<string>('')

    const [isUrlFieldValid, setIsUrlFieldValid] = useState<boolean>(true)
    const handleUrlField = (value: string, target: 'youtubeLink' | 'youtubeTitle') => {
        if (target === 'youtubeLink') setYoutubeLink(value)

        if (target === 'youtubeTitle') setYoutubeTitle(value)
    }

    useEffect(() => {
        setYoutubeTitleMessage('')
        setYoutubeLinkMessage('')
        if (youtubeLink) {
            const url = z.string().url()
            const safeParse = url.safeParse(youtubeLink)
            if (!safeParse.success) {
                setIsUrlFieldValid(false)
                setYoutubeLinkMessage('動画URLを入力してください')
                return
            }
            if (!checkYoutubeUrlPattern(youtubeLink)) {
                setIsUrlFieldValid(false)
                setYoutubeLinkMessage('動画URLはYouTubeのURLを入力してください')
                return
            }
            if (links.some((i) => i.url === youtubeLink)) {
                setIsUrlFieldValid(false)
                setYoutubeLinkMessage('動画URLはすでに存在しています。')
                return
            }
        }
        if (youtubeTitle && youtubeLink) {
            setIsUrlFieldValid(true)
            setYoutubeTitleMessage('')
            setYoutubeLinkMessage('')
            return
        }
        if (!youtubeTitle && youtubeLink) {
            setIsUrlFieldValid(false)
            setYoutubeTitleMessage('動画タイトルを入力してください')
        }
        if (youtubeTitle && !youtubeLink) {
            setIsUrlFieldValid(false)
            setYoutubeLinkMessage('動画URLを入力してください')
        }
        if (!youtubeTitle && !youtubeLink) {
            setIsUrlFieldValid(false)
            setYoutubeTitleMessage('')
            setYoutubeLinkMessage('')
            return
        }
    }, [youtubeTitle, youtubeLink])

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
        setValue,
    } = useForm<ClientMemoryCreateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientMemoryCreateDtoSchema),
    })

    // 初期化
    useEffect(() => {
        reset({
            name: memory?.name ?? '',
            year: memory?.year ?? dayjs().year().toString(),
            month: memory?.month ?? '',
            content: memory?.content ?? '',
            fileUuid: memory?.file?.uuid || null,
        })
        setImage(memory?.file || null)
    }, [memory])

    const createMemoryAsset = async (youtubeUrls: YoutubeURL[], medias: Schemas.FileEntities[], memoryUuid: string) => {
        const createAssets = async (file: Schemas.FileEntities | YoutubeURL) => {
            try {
                const requestBody = 'url' in file ? { ...file } : { fileUuid: (file as Schemas.FileEntities).uuid }
                await apiClient.clientMemoryAssetCreate({
                    requestBody,
                    parameter: { memoryUuid: memoryUuid ?? '' },
                })
            } catch (e) {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            }
        }

        const filteredMediaPromises = medias
            .filter((file) => !memoryAssetList?.list?.some((asset) => asset.file?.uuid === file.uuid))
            .map((file) => createAssets(file))

        const filteredYoutubePromises = youtubeUrls
            .filter((file) => !memoryAssetList?.list?.some((asset) => asset.url === file.url))
            .map((file) => createAssets(file))

        await Promise.all([...filteredMediaPromises, ...filteredYoutubePromises])
    }

    const submitHandler: SubmitHandler<ClientMemoryCreateDtoSchema> = async (data) => {
        if (!memoryUuid) return
        const confirmed = await queueDialog({
            type: 'confirm',
            title: memoryUuid === 'new' ? '作成' : '保存',
            text: `${memoryUuid === 'new' ? '作成' : '保存'}します。よろしいでしょうすか？`,
        })

        if (!confirmed) return

        const requestBody: Schemas.ClientMemoryCreateDto = { ...data, dailyLife: false }

        try {
            const res =
                memoryUuid === 'new'
                    ? await apiClient.clientMemoryCreate({ requestBody })
                    : await apiClient.clientMemoryUpdate({ parameter: { memoryUuid }, requestBody })

            await createMemoryAsset(links, uploadedImages, res.uuid)
            cancelHandler()
        } catch (e) {
            const message = e instanceof AxiosError ? e.response?.data.message || e.message : '更新に失敗しました'
            await queueDialog({ type: 'alert', title: 'エラーが発生しました', text: message })
        }
    }
    const cancelHandler = () => {
        if (!memoryUuid) return
        if (memoryUuid === 'new') navigate!('/memory', { replace: true })
        else navigate!(`/memory/${memoryUuid}`, { replace: true })
    }

    const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files
        if (!files || files.length === 0) return

        Object.keys(files).forEach(async (_, i) => {
            const formData = new FormData()
            formData.append('files', files[i] as Blob)
            // @ts-expect-error
            return await apiClient.clientFileUploadUpload({ requestBody: formData }).then((uploadedFiles) => {
                setUploadedImages((q) => [...q, ...uploadedFiles])
            })
        })
    }

    const onFileDialogOpen = (i: Schemas.FileEntities) => {
        setImage(i)
        setDialogOpen(true)
    }

    const onFileDialogClose = () => {
        setImage(null)
        setDialogOpen(false)
    }

    const onFileDialogImageDelete = async () => {
        const asset = memoryAssetList?.list?.find((i) => i.file?.uuid === image?.uuid)

        const confirmed = await queueDialog({
            type: 'confirm',
            title: '削除',
            text: '削除します。よろしいでしょうか？',
        })
        if (!confirmed) return

        const handleImageDeletion = () => {
            setUploadedImages((q) => q.filter((x) => x.uuid !== image?.uuid))
            setImage(null)
            setDialogOpen(false)
        }

        if (asset) {
            try {
                await apiClient.clientMemoryAssetDelete({
                    parameter: { memoryUuid: memoryUuid ?? '', assetUuid: asset.uuid ?? '' },
                })
            } catch (e) {
                const message = e instanceof AxiosError ? e.response?.data.message || e.message : '更新に失敗しました'
                await queueDialog({ type: 'alert', title: 'エラーが発生しました', text: message })
            }
        }

        handleImageDeletion()
    }

    // ファイルアップロードモーダルの表示状態
    const [image, setImage] = useState<Schemas.FileEntities | null>(memory?.file || null)
    const handleUploadFile = (value?: Schemas.FileEntities) => {
        if (value?.uuid) {
            setValue('fileUuid', value.uuid)
            setImage(value)
        }
    }
    const handleDeleteFile = () => {
        setValue('fileUuid', null)
        setImage(null)
    }

    const addedImages = useMemo(() => {
        return uploadedImages.map((i, k) => {
            return (
                <Grid item xs={3.5} sm={3.8} key={k}>
                    <Box
                        component={'img'}
                        src={mediaUrl(i)}
                        alt=""
                        sx={{
                            width: { xs: '27.8vmin', sm: '27.8vmin' },
                            maxWidth: '220px',
                            height: { xs: '27.8vmin', sm: '27.8vmin' },
                            maxHeight: '220px',
                            objectPosition: 'center',
                            objectFit: 'cover',
                            cursor: 'pointer',
                        }}
                        onClick={() => onFileDialogOpen(i)}
                    />
                </Grid>
            )
        })
    }, [uploadedImages])

    const onLinkRemoved = async (url: string) => {
        const confirmed = await queueDialog({
            type: 'confirm',
            title: '削除',
            text: '削除します。よろしいでしょうか？',
        })
        if (!confirmed) return
        const assetUuid = memoryAssetList?.list?.find((i) => i.url === url)?.uuid ?? ''

        try {
            if (assetUuid) {
                await apiClient.clientMemoryAssetDelete({
                    parameter: { memoryUuid: memoryUuid ?? '', assetUuid },
                })
            }
            setLinks((prevLinks) => prevLinks.filter((link) => link.url !== url))
        } catch (e) {
            const message = e instanceof AxiosError ? e.response?.data.message || e.message : '削除に失敗しました'
            await queueDialog({ type: 'alert', title: 'エラーが発生しました', text: message })
        }
    }

    const addedLinks = useMemo(() => {
        return links.map((i, k) => {
            return (
                <Box
                    display={'flex'}
                    key={k}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    width={'100%'}
                    gap={1}
                    {...(k !== links.length - 1 && { borderBottom: '1px #AF985A solid' })}
                    py={'10px'}>
                    <Box display={'flex'} flexWrap={'wrap'} sx={{ cursor: 'pointer' }}>
                        <Link
                            fontSize={'1rem'}
                            fontWeight={'700'}
                            color={'#77618B'}
                            target={'_blank'}
                            rel={'noreferrer'}
                            sx={{ textDecoration: 'underline' }}
                            href={i.url}>
                            {i.title}
                        </Link>
                    </Box>
                    <Button variant={'contained'} onClick={() => onLinkRemoved(i.url ?? '')} color={'error'}>
                        削除
                    </Button>
                </Box>
            )
        })
    }, [links])

    const handleYoutubeLinkAdd = () => {
        setLinks((q) => [
            ...q,
            {
                title: youtubeTitle,
                url: youtubeLink,
            },
        ])
        setYoutubeTitle('')
        setYoutubeLink('')
    }

    return {
        memory,
        onFileDialogClose,
        onFileDialogImageDelete,
        dialogOpen,
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        submitHandler,
        uploadedImages,
        image,
        handleUploadFile,
        onFileChange,
        handleDeleteFile,
        cancelHandler,
        addedImages,
        addedLinks,
        links,
        isUrlFieldValid,
        handleUrlField,
        youtubeLink,
        youtubeTitle,
        youtubeLinkMessage,
        youtubeTitleMessage,
        handleYoutubeLinkAdd,
    }
}

export const MemoryMemoryUuidEditPage: FC = () => {
    const {
        memory,
        onFileDialogClose,
        onFileDialogImageDelete,
        dialogOpen,
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        submitHandler,
        uploadedImages,
        image,
        handleUploadFile,
        onFileChange,
        handleDeleteFile,
        cancelHandler,
        addedImages,
        addedLinks,
        links,
        isUrlFieldValid,
        handleUrlField,
        youtubeLink,
        youtubeTitle,
        youtubeLinkMessage,
        youtubeTitleMessage,
        handleYoutubeLinkAdd,
    } = useMemoryMemoryUuidEditPage()
    return (
        <>
            <DefaultLayout title={`思い出を${memory?.uuid ? '編集' : '作成'}`} breadcrumbList={[]} tabValue={'memory'}>
                <Stack spacing={2}>
                    <Stack spacing={3}>
                        <Stack direction={'column'} alignItems={'center'}>
                            {image ? (
                                <>
                                    <img
                                        alt={''}
                                        src={mediaUrl(image, true)}
                                        style={{ width: 240, height: 240, objectFit: 'cover', borderRadius: '10px' }}
                                    />
                                    <Button onClick={() => handleDeleteFile()}>削除</Button>
                                </>
                            ) : (
                                <>
                                    <CMemoryUpload
                                        completionHandler={handleUploadFile}
                                        label={''}
                                        error={!!errors.fileUuid}
                                        helperText={errors.fileUuid?.message}
                                    />
                                </>
                            )}
                        </Stack>

                        <Stack spacing={0.5}>
                            <CInputLabel label={'できごとタイトル'} required />
                            <TextField
                                id={'name'}
                                required={true}
                                {...register('name')}
                                placeholder={'入力してください'}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                variant={'outlined'}
                            />
                        </Stack>
                        <Stack spacing={1}>
                            <CInputLabel label={'年・月'} required />
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <TextField
                                    id={'year'}
                                    {...register('year')}
                                    placeholder={'入力してください'}
                                    defaultValue={'2024'}
                                    variant={'outlined'}
                                />
                                <Typography variant={'body2'}>年</Typography>
                                <TextField
                                    id={'month'}
                                    {...register('month')}
                                    placeholder={'入力してください'}
                                    variant={'outlined'}
                                />
                                <Typography variant={'body2'}>月</Typography>
                            </Stack>
                            <Stack spacing={2}>
                                {errors.year && (
                                    <Box color={'#EA5F4C'} fontWeight={'2px'} paddingX={2}>
                                        {errors.year?.message}
                                    </Box>
                                )}
                                {errors.month && (
                                    <Box color={'#EA5F4C'} fontWeight={'2px'} paddingX={2}>
                                        {errors.month?.message}
                                    </Box>
                                )}
                            </Stack>
                        </Stack>
                        <Stack spacing={0.5}>
                            <CInputLabel label={'詳細'} required />
                            <TextField
                                id={'content'}
                                {...register('content')}
                                placeholder={'入力してください'}
                                error={!!errors.content}
                                helperText={errors.content?.message}
                                multiline
                                rows={3}
                                variant={'outlined'}
                            />
                        </Stack>
                        <Stack pt={'1.5rem'} spacing={1} borderTop={'1px solid #AF985A'}>
                            <Typography variant={'subtitle2'}>メディア</Typography>
                            <Typography variant={'body1'}>画像を添付することができます。</Typography>
                            <Stack
                                spacing={2}
                                direction={'row'}
                                bgcolor={'#F4F0ED'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                py={'34px'}
                                borderRadius={'10px'}>
                                <LoadingButton
                                    sx={{ fontWeight: 'normal' }}
                                    variant={'text'}
                                    startIcon={<img alt="" src={addIcon} />}
                                    component={'label'}>
                                    ファイルを追加
                                    <input
                                        hidden
                                        type={'file'}
                                        accept={'.jpeg,.jpg,.png,.gif'}
                                        multiple
                                        onChange={onFileChange}
                                    />
                                </LoadingButton>
                            </Stack>
                        </Stack>
                        {uploadedImages.length > 0 && (
                            <Stack p={2} bgcolor={'#F4F0ED'} borderRadius={'10px'} spacing={'10px'} justifyContent={'center'}>
                                <Typography variant={'body1'}>追加した画像</Typography>
                                <Grid container gap={2}>
                                    {addedImages}
                                </Grid>
                            </Stack>
                        )}
                        <Stack spacing={1} borderTop={'1px solid #AF985A'} pt={'1.5rem'}>
                            <Typography variant={'subtitle2'}>動画URL</Typography>
                            <Typography variant={'body1'}>
                                YouTubeにアップロードしている動画を載せることができます。動画タイトルとURLを入力してください。
                            </Typography>
                            <Stack spacing={1}>
                                <Stack spacing={0.5}>
                                    <Typography variant={'body1'}>動画タイトル</Typography>
                                    <TextField
                                        value={youtubeTitle}
                                        placeholder={'動画タイトルを入力'}
                                        onChange={(e) => handleUrlField(e.target.value, 'youtubeTitle')}
                                        variant={'outlined'}
                                        fullWidth
                                    />
                                    {!!youtubeTitleMessage.length && (
                                        <Box color={'#EA5F4C'} fontWeight={'2px'} paddingX={2}>
                                            {youtubeTitleMessage}
                                        </Box>
                                    )}
                                </Stack>
                                <Stack spacing={0.5}>
                                    <Typography variant={'body1'}>動画URL</Typography>
                                    <TextField
                                        value={youtubeLink}
                                        placeholder={'http://'}
                                        fullWidth
                                        onChange={(e) => handleUrlField(e.target.value, 'youtubeLink')}
                                        variant={'outlined'}
                                    />
                                    {!!youtubeLinkMessage.length && (
                                        <Box color={'#EA5F4C'} fontWeight={'2px'} paddingX={2}>
                                            {youtubeLinkMessage}
                                        </Box>
                                    )}
                                </Stack>
                                <Stack width={'100%'} alignItems={'center'}>
                                    <Button
                                        disabled={!isUrlFieldValid}
                                        variant={'contained'}
                                        fullWidth
                                        sx={{ fontSize: '1.2rem', height: '4.063rem' }}
                                        onClick={handleYoutubeLinkAdd}>
                                        追加
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                        {links.length > 0 && (
                            <Stack p={2} bgcolor={'#F4F0ED'} borderRadius={'10px'} spacing={'10px'}>
                                <Typography variant={'body1'}>追加した動画</Typography>
                                <Box display={'flex'} flexWrap={'wrap'} gap={2}>
                                    {addedLinks}
                                </Box>
                            </Stack>
                        )}
                        <Stack borderTop={'1px solid #AF985A'} pt={'2rem'}>
                            <LoadingButton
                                variant={'contained'}
                                loading={isSubmitting}
                                disabled={!isValid}
                                onClick={handleSubmit!(submitHandler)}>
                                {memory?.uuid ? '保存' : '作成'}
                            </LoadingButton>
                        </Stack>
                        <Button variant="outlined" onClick={() => cancelHandler!()}>
                            キャンセル
                        </Button>
                    </Stack>
                </Stack>
            </DefaultLayout>
            {image && (
                <CPersonalHistoryDisplayFileDialog
                    isOpen={dialogOpen}
                    deleteHandler={onFileDialogImageDelete}
                    onClose={onFileDialogClose}
                    imageFile={image}
                />
            )}
        </>
    )
}
