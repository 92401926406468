import CloseSharpIcon from '@mui/icons-material/CloseSharp'
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, useMediaQuery, useTheme } from '@mui/material'
import { FC, useEffect, useState } from 'react'

import { Schemas } from '~/apis/types'
import trash from '~/assets/image/memory/trash.svg'
import { mediaUrl } from '~/utils/common'

export type CMemoryDisplayFileDialogProps = {
    isOpen: boolean
    onClose: () => void
    deleteHandler: (asset: Schemas.MemoryAssetEntities) => void
    entity?: Schemas.MemoryAssetEntities
}

export const useCMemoryDisplayFileDialog = (
    deleteHandler: (asset: Schemas.MemoryAssetEntities) => void,
    isOpen: boolean,
    entity?: Schemas.MemoryAssetEntities,
) => {
    const [image, setImage] = useState<Schemas.FileEntities | undefined>(entity?.file || undefined)
    // 初期化
    useEffect(() => {
        setImage(entity?.file || undefined)
    }, [isOpen, entity])

    const handleDelete = () => {
        if (!entity) return
        deleteHandler(entity)
    }

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

    return {
        handleDelete,
        image,
        fullScreen,
    }
}

export const CMemoryDisplayFileDialog: FC<CMemoryDisplayFileDialogProps> = ({ isOpen, onClose, deleteHandler, entity }) => {
    const { handleDelete, image, fullScreen } = useCMemoryDisplayFileDialog(deleteHandler, isOpen, entity)
    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullScreen={fullScreen} sx={{ background: '#000000' }} maxWidth={'sm'}>
                <DialogTitle sx={{ background: '#000000' }}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Button color={'error'} startIcon={<img src={trash} />} onClick={() => handleDelete()}>
                            削除
                        </Button>
                        <IconButton sx={{ color: '#FFF' }} onClick={() => onClose()}>
                            <CloseSharpIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent sx={{ background: '#000000', p: 0, m: 0 }}>
                    <Stack direction={'row'} justifyContent={'center'} p={0} m={0}>
                        <img
                            src={mediaUrl(image)}
                            style={{ width: '90%', height: 'auto' }}
                            // alt={image.filename}
                        />
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    )
}
