import { useDisclosure } from '@chakra-ui/hooks'
import { Box, Button, CircularProgress, Container, IconButton, Menu, MenuItem, MenuList, Stack, Typography } from '@mui/material'
import { useInfiniteQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { FC, useMemo, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

import helpIcon from '~/assets/image/connect/helpIcon.svg'
import memoryAdd from '~/assets/image/memory/addMemory.svg'
import moreHorizontal from '~/assets/image/memory/moreHorizontal.svg'
import { CMemoryFileDialog } from '~/components/functional/memory/cMemoryFileDialog/CMemoryFileDialog'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type QueryParameters = {
    page: number
    limit: number
}

export const useMemoryIndexPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()

    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
    const [menuUuid, setMenuUuid] = useState<string | undefined>(undefined)
    const menuButtonHandler = (e: HTMLElement, uuid: string) => {
        setMenuAnchorEl(e)
        setMenuUuid(uuid)
    }
    const menuCloseHandler = () => {
        setMenuAnchorEl(null)
    }
    const handleMenuItem = () => {
        navigate(`/memory/${menuUuid}/edit`)
    }
    const [loading, setLoading] = useState(false)
    const page = 1
    const limit = 10
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [queryParams, setQueryParams] = useState<QueryParameters>({ page, limit })

    // initial fetch
    const {
        data: listResponse,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
        refetch: fetchList,
    } = useInfiniteQuery(
        ['/memory'],
        async ({ pageParam = 1 }) => {
            return await apiClient.clientMemoryGetList({ parameter: { ...queryParams, page: pageParam } })
        },
        {
            getNextPageParam: (lastPage, pages) => {
                if (!lastPage.count) return undefined
                const totalPages = Math.ceil(lastPage.count / queryParams.limit)

                if (totalPages > pages.length) return pages.length + 1

                return undefined
            },
            retry: false,
            refetchOnWindowFocus: false,
            suspense: true,
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )
    const { data: imageMaster } = useQuerySuspense(
        ['/memory/imageMaster'],
        async () => {
            return await apiClient.clientImageMasterShow({ parameter: { code: 'memoryEveryday' } })
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )
    const memoryList = useMemo(() => {
        return listResponse?.pages?.map((page) => {
            return page.list?.map((memory) => {
                return (
                    <Box key={memory.uuid}>
                        <Stack direction={'row'}>
                            <img
                                onClick={() => navigate(`/memory/${memory.uuid}`)}
                                src={memory?.file ? mediaUrl(memory.file) : mediaUrl(imageMaster?.file)}
                                style={{
                                    minWidth: '100px',
                                    minHeight: '100px',
                                    maxWidth: '100px',
                                    maxHeight: '100px',
                                    objectPosition: 'center',
                                    objectFit: 'cover',
                                    borderRadius: '10px',
                                    cursor: 'pointer',
                                }}
                                alt=""
                            />
                            <Stack
                                direction={'column'}
                                justifyContent={'center'}
                                alignItems={'flex-start'}
                                pl={1}
                                width={'100%'}
                                onClick={() => navigate(`/memory/${memory.uuid}`)}
                                sx={{ cursor: 'pointer' }}>
                                <Typography variant={'body2'} sx={{ whiteSpace: 'pre-wrap' }}>
                                    {memory.name}
                                </Typography>
                                <Typography variant={'body1'} sx={{ whiteSpace: 'pre-wrap' }}>
                                    {`${memory.year}年` + (memory.month && `${memory.month}月`)}
                                </Typography>
                                <Typography variant={'body1'} sx={{ whiteSpace: 'pre-wrap' }}>
                                    {`コメント(${memory.commentCount}) / メンバー(${memory.connects.length + 1})`}
                                </Typography>
                            </Stack>
                            <Stack justifyContent={'flex-end'} alignItems={'end'}>
                                <IconButton onClick={(e) => menuButtonHandler(e.currentTarget, memory.uuid)}>
                                    <img src={moreHorizontal} style={{ cursor: 'pointer', width: '24px' }} />
                                </IconButton>
                            </Stack>
                        </Stack>
                    </Box>
                )
            })
        })
    }, [listResponse?.pages, queryParams.page])
    const loadSpinner = useMemo(() => {
        if (listResponse?.pages && listResponse.pages[0].count! > 0) {
            return (
                hasNextPage && (
                    <InView
                        as={'div'}
                        width={'full'}
                        onChange={(inView) => {
                            if (inView) {
                                setLoading(true)
                                setTimeout(async () => await fetchNextPage(), 1000)
                            }
                        }}>
                        {(isFetchingNextPage || loading) && (
                            <CircularProgress sx={{ width: '48px', height: '48px' }} color={'primary'} />
                        )}
                    </InView>
                )
            )
        }
    }, [hasNextPage, isFetchingNextPage, loading, listResponse?.pages])

    const deleteHandler = async () => {
        try {
            menuCloseHandler()
            const message = '削除します。よろしいでしょうか？'
            if (!menuUuid) return
            if (
                await queueDialog({
                    type: 'confirm',
                    title: '削除',
                    text: message,
                })
            ) {
                await apiClient.clientMemoryDelete({ parameter: { memoryUuid: menuUuid } })
                await fetchList()
                setMenuUuid(undefined)
            }
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const { isOpen: editDialogIsOpen, onOpen: updateModalOnOpen, onClose: editDialogOnClose } = useDisclosure()
    const addButtonHandler = () => {
        updateModalOnOpen()
        menuCloseHandler()
    }
    const editDialogSubmitHandler = async (dto: { fileUuids: string[] }) => {
        try {
            if (!menuUuid) return
            const promises = dto.fileUuids.map(
                async (i) =>
                    await apiClient.clientMemoryAssetCreate({
                        parameter: { memoryUuid: menuUuid! },
                        requestBody: { fileUuid: i },
                    }),
            )
            await Promise.all(promises)
            // else await apiClient.clientMemoryUpdate({ parameter: { memoryUuid }, requestBody: dto })

            editDialogOnClose()
            setMenuUuid(undefined)
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }
    const memoryFileDialog = (
        <CMemoryFileDialog isOpen={editDialogIsOpen} onClose={editDialogOnClose} onSubmit={editDialogSubmitHandler} />
    )

    return {
        menuAnchorEl,
        menuCloseHandler,
        handleMenuItem,
        navigate,
        memoryList,
        loadSpinner,
        deleteHandler,
        addButtonHandler,
        memoryFileDialog,
    }
}

export const MemoryIndexPage: FC = () => {
    const {
        menuAnchorEl,
        menuCloseHandler,
        handleMenuItem,
        navigate,
        memoryList,
        loadSpinner,
        deleteHandler,
        addButtonHandler,
        memoryFileDialog,
    } = useMemoryIndexPage()
    return (
        <>
            <DefaultLayout maxWidth={'md'} breadcrumbList={[]} tabValue={'memory'}>
                <Container maxWidth={'sm'}>
                    <Stack direction={'row'} justifyContent={'end'} sx={{ pb: 2 }}>
                        <IconButton component={Link} to="/help?source=memory">
                            <img src={helpIcon} />
                        </IconButton>
                    </Stack>
                    <Stack spacing={2}>
                        <Stack spacing={4}>
                            <Box onClick={() => navigate('/memory/new/edit')} gap={4}>
                                <Stack direction={'row'}>
                                    <img
                                        src={memoryAdd}
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            objectFit: 'contain',
                                            borderRadius: '10px',
                                            cursor: 'pointer',
                                        }}
                                        alt=""
                                    />
                                    <Stack
                                        direction={'column'}
                                        justifyContent={'center'}
                                        alignItems={'flex-start'}
                                        pl={1}
                                        sx={{ cursor: 'pointer' }}>
                                        <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                                            思い出を作成
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Box>
                            <Stack gap={4}>{memoryList}</Stack>
                            <Stack alignItems={'center'}>{loadSpinner}</Stack>
                        </Stack>
                        <Menu
                            open={!!menuAnchorEl}
                            onClose={menuCloseHandler}
                            anchorEl={menuAnchorEl}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    boxShadow: 1,
                                    px: 1,
                                    placement: 'bottom-end',
                                },
                            }}>
                            <Typography variant={'body2'} textAlign={'center'} sx={{ borderBottom: 1, pb: 1 }}>
                                操作を選択してください
                            </Typography>
                            <MenuList dense>
                                <MenuItem
                                    divider
                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                    onClick={() => addButtonHandler()}>
                                    <Button variant={'text'} color={'primary'}>
                                        画像を追加
                                    </Button>
                                </MenuItem>
                                <MenuItem
                                    divider
                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                    onClick={() => handleMenuItem()}>
                                    <Button variant={'text'} color={'primary'}>
                                        思い出を編集
                                    </Button>
                                </MenuItem>
                                <MenuItem sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => deleteHandler()}>
                                    <Button variant={'text'} color={'error'}>
                                        思い出を削除
                                    </Button>
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </Stack>
                </Container>
            </DefaultLayout>
            {memoryFileDialog}
        </>
    )
}
