import { Box, Stack, Typography } from '@mui/material'
import { type FC, type ReactNode } from 'react'

import comments from '~/assets/image/help/mypage/memory/comments.png'
import commentsThree from '~/assets/image/help/mypage/memory/commentsThree.png'
import commentsTwo from '~/assets/image/help/mypage/memory/commentsTwo.png'
import memoryCreation from '~/assets/image/help/mypage/memory/memoryCreation.png'
import memoryCreation2 from '~/assets/image/help/mypage/memory/memoryCreation2.png'
import shareAlbumTwo from '~/assets/image/help/mypage/memory/shareAlbumTwo.png'
import sharedMemories from '~/assets/image/help/mypage/memory/sharedMemories.png'
import { CHelpImage } from '~/components/functional/help/cHelpImage/CHelpImage'

import { CHelpCommonLink } from '../cHelpCommonLink/CHelpCommonLink'
import { CHelpCustomList } from '../cHelpCustomList/CHelpCustomList'
import { CHelpLayout } from '../cHelpLayout/CHelpLayout'

type MemoryHelpItems = {
    heading: string
    content: ReactNode
    image?: string
}
type MemorySectionItems = {
    id: string
    title: string
    body?: ReactNode
    children?: ReactNode
}

export const useCMemoryHelp = (onLinkClick: (sectionName: string, pathName: string, newTab: boolean) => void) => {
    const shareAlbumsItems: MemoryHelpItems[] = [
        {
            heading: '確認方法',
            content: (
                <>
                    作成済みの思い出を選択し、思い出トップ画面中央の <b>コメント</b> タブから確認できます。
                </>
            ),
            image: comments,
        },
        {
            heading: 'コメントを追加',
            content: (
                <>
                    <b>コメント</b> タブ内の画面下部にあるコメント入力欄にコメントを入力してください。
                    <br />
                    コメントを入力し終えたら、入力欄右のボタンをタップし、送信するとコメントが送信されます。
                </>
            ),
            image: commentsTwo,
        },
        {
            heading: '公開範囲',
            content: (
                <>
                    コメントは、その思い出の共有メンバー全員に公開されます。
                    <br />
                    作成済みの思い出を選択し、思い出トップ画面中央の <b>メンバー</b>
                    タブを開くと、その思い出の共有メンバーを確認することができます。
                </>
            ),
            image: commentsThree,
        },
    ]

    const sharedMemoryItems: MemoryHelpItems[] = [
        {
            heading: '確認方法',
            content: <>共有されている思い出は、自分の思い出と同じところに表示されます。</>,
            image: sharedMemories,
        },
        {
            heading: '追加、編集、削除方法',
            content: (
                <>
                    思い出への画像・コメント・Youtube動画の追加方法は自分が作成したの思い出の操作と同じです。
                    <br />
                    また、思い出自体の削除もできるほか、コメントを削除することもできます。
                    <br />
                </>
            ),
        },
    ]

    const shareAlbumsList = shareAlbumsItems.map((it, idx) => {
        return (
            <Stack key={idx} spacing={'0.625rem'} pt={'1rem'}>
                <Typography variant={'subtitle1'}>{it.heading}</Typography>
                <Typography variant={'body2'}>{it.content}</Typography>
                {it.image && <CHelpImage src={it.image} />}
            </Stack>
        )
    })

    const sharedMemoryList = sharedMemoryItems.map((it, idx) => {
        return (
            <Stack key={idx} spacing={'0.625rem'} pt={'1rem'}>
                <Typography variant={'subtitle1'}>{it.heading}</Typography>
                <Typography variant={'body2'}>{it.content}</Typography>
                {it.image && <CHelpImage src={it.image} />}
            </Stack>
        )
    })

    const memorySectionItems: MemorySectionItems[] = [
        {
            id: 'memoryCreation',
            title: '思い出作成',
            body: <>旅行先での画像やYoutube動画を登録し、思い出を見返すことができます。つながっている人とも共有できます。</>,
            children: (
                <>
                    <Stack spacing={'0.625rem'} pt={'0.5rem'}>
                        <Typography variant={'subtitle1'}>思い出追加方法</Typography>
                        <CHelpCustomList
                            listStyleType={'disc'}
                            listItems={[
                                <>
                                    <b>思い出を作成</b>を押します。
                                </>,
                                <>
                                    思い出の表紙に設定したい画像を<b>表紙画像を追加</b>から追加してください。
                                </>,
                                <>
                                    <b>
                                        思い出のできごとタイトル、思い出の年・月、思い出の説明、思い出の添付ファイル、思い出のYoutube動画
                                    </b>
                                    を設定します。
                                </>,
                                <>
                                    <b>作成</b>を押すと思い出が作成されます。
                                </>,
                            ]}
                        />
                        <CHelpImage src={memoryCreation} />
                    </Stack>
                    <Stack spacing={'0.625rem'} pt={'0.5rem'}>
                        <Typography variant={'subtitle1'}>思い出の編集・削除</Typography>
                        <Typography variant={'body2'}>
                            作成した思い出の右下にある…ボタンをタップすると、<b>画像の追加・思い出の編集・思い出の削除</b>
                            を行うことができます。
                        </Typography>
                        <CHelpImage src={memoryCreation2} />
                    </Stack>
                    <Stack spacing={'0.625rem'} pt={'0.5rem'}>
                        <Typography variant={'subtitle1'}>公開範囲</Typography>
                        <Typography variant={'body2'}>
                            共有メンバーを設定することで、思い出を指定したユーザーと共有することがきます。
                            <br />
                            共有メンバーを設定しない場合は誰にも公開されません。詳しくは
                            <CHelpCommonLink onClick={() => onLinkClick('shareMemory', 'memory', false)}>
                                思い出の共有
                            </CHelpCommonLink>
                            をご覧ください。
                        </Typography>
                    </Stack>
                </>
            ),
        },
        {
            id: 'shareMemory',
            title: '思い出の共有',
            children: (
                <>
                    <Stack spacing={'0.625rem'}>
                        <Typography variant={'body2'}>
                            思い出を共有すると、共有されたユーザーは以下のことが可能になります。
                        </Typography>

                        <Box>
                            <Typography variant={'body2'}>できること</Typography>
                            <CHelpCustomList
                                listStyleType={'disc'}
                                listItems={[
                                    <>思い出の閲覧、編集、削除</>,
                                    <>コメントの閲覧、追加、削除</>,
                                    <>思い出のメンバーの閲覧、編集、削除</>,
                                ]}
                            />
                        </Box>

                        <Box>
                            <Typography variant={'body2'}>できないこと</Typography>
                            <CHelpCustomList
                                listStyleType={'disc'}
                                listItems={[
                                    <>共有されていない思い出の閲覧</>,
                                    <>メンバーからつながってないユーザーは削除できません。</>,
                                ]}
                            />
                        </Box>
                    </Stack>
                    <Stack spacing={'0.625rem'} pt={'0.5rem'}>
                        <Typography variant={'subtitle1'}>共有ユーザーの追加・編集</Typography>
                        <CHelpCustomList
                            listStyleType={'decimal'}
                            paddingBottom={'12px'}
                            listItems={[
                                <>
                                    作成済みの思い出を選択し、思い出トップ画面中央の<b>メンバー</b>タブを開き、
                                    <b>共有メンバーを編集</b>ボタンから共有したいユーザーを選択してください。
                                    <CHelpImage src={shareAlbumTwo} />
                                </>,
                            ]}
                        />
                    </Stack>
                </>
            ),
        },
        {
            id: 'comments',
            title: 'コメント機能',
            body: (
                <Typography variant={'body2'}>
                    思い出にはそれぞれ好きなタイミングでコメントを追加できます。
                    <br />
                    共有ユーザーがいる場合、お互いのコメントが相手にも見えるようになります。
                </Typography>
            ),
            children: <Stack pt={'0.5rem'}>{shareAlbumsList}</Stack>,
        },
        {
            id: 'sharedMemories',
            title: '共有された思い出',
            body: (
                <Typography variant={'body2'}>
                    共有された思い出を、共有メンバーみんなで編集できます。お互いのコメントが相手にも見えるようになります。
                </Typography>
            ),
            children: <Stack pt={'0.5rem'}>{sharedMemoryList}</Stack>,
        },
    ]
    return { memorySectionItems }
}

export type CMemoryHelpProps = {
    onLinkClick: (sectionName: string, pathName: string) => void
}

export const CMemoryHelp: FC<CMemoryHelpProps> = ({ onLinkClick }) => {
    const { memorySectionItems } = useCMemoryHelp(onLinkClick)
    return (
        <Stack spacing={'2.5rem'} mt={'2.5rem'} data-testid="c-memory-help">
            {memorySectionItems.map((it, idx) => (
                <CHelpLayout key={idx} {...it} />
            ))}
        </Stack>
    )
}
