import { LoadingButton } from '@mui/lab'
import { Box, Button, Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { type FC, ChangeEvent, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export type RelativeGraveEditPicRelativeIdPageViewProps = {}

const useRelativeGraveEditPicPage = () => {
    const { connectUuid } = useParams()
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()

    const apiClient = createApiClient()

    const onErrorHandle = async (e: unknown) => {
        let message = '取得に失敗しました'
        if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
        await queueDialog({
            type: 'alert',
            title: 'エラーが発生しました',
            text: message,
        })
    }
    const { data: connect } = useQuerySuspense(
        ['connectEdit', connectUuid],
        async () => {
            return await apiClient.clientConnectGetConnect({ parameter: { connectUuid: connectUuid ?? '' } })
        },
        { onError: onErrorHandle },
    )

    const { data: grave } = useQuerySuspense(
        ['connectGraveChange', connectUuid],
        async () => {
            return await apiClient.clientConnectGraveGetConnectGrave({ parameter: { connectUuid: connectUuid ?? '' } })
        },
        { onError: onErrorHandle },
    )
    const [image, setImage] = useState(grave?.file)

    const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files

        if (!files || files.length === 0) return

        const formData = new FormData()
        formData.append('files', files[0] as Blob)

        try {
            // @ts-expect-error
            const uploadedFiles = await apiClient.clientFileUploadUpload({ requestBody: formData })
            setImage(uploadedFiles.at(0))
        } catch (e) {
            console.log(e)
        }
    }

    const onSave = async () => {
        if (!connect) return
        return await apiClient
            .clientConnectUpdateRelativeGrave({
                parameter: { connectUuid: connect.uuid },
                requestBody: {
                    ...grave,
                    name: grave?.name ?? '',
                    posthumousName: grave?.posthumousName ?? '',
                    denomination: grave?.denomination ?? '',
                    fileUuid: image?.uuid ?? '',
                },
            })
            .then(() => navigate(`/connectList/${connectUuid}/grave`))
            .catch(onErrorHandle)
    }

    return { onChange, onSave, image, connectUuid, navigate, setImage }
}
export const RelativeGraveEditPicRelativeIdPageView: FC<RelativeGraveEditPicRelativeIdPageViewProps> = () => {
    const { onChange, onSave, image, navigate, connectUuid, setImage } = useRelativeGraveEditPicPage()
    return (
        <DefaultLayout title={'お墓の写真をアップロード'} breadcrumbList={[]}>
            <Stack alignItems={'center'}>
                <Typography variant={'body1'}>実際のお墓の写真を設定することができます</Typography>
            </Stack>
            <Stack mt={'1.5rem'}>
                <LoadingButton variant={'contained'} fullWidth component={'label'}>
                    <input hidden type={'file'} accept={'.jpeg,.jpg,.png,.gif'} onChange={onChange} />
                    写真をアップロード
                </LoadingButton>
            </Stack>
            <Box mt={'40px'}>
                <img src={mediaUrl(image)} height={'400px'} width={'100%'} style={{ objectFit: 'contain' }} />
            </Box>
            <Box display={'flex'} gap={'1rem'} width={'100%'} mt={'20px'}>
                <Button
                    onClick={() => navigate(`/connectList/${connectUuid}/grave`, { replace: true })}
                    variant={'outlined'}
                    fullWidth>
                    キャンセル
                </Button>
                <Button variant={'contained'} fullWidth onClick={onSave}>
                    この画像で保存
                </Button>
            </Box>
            <Button
                disabled={!image}
                sx={{ mt: 2, color: '#2A2928', bgcolor: '#E8DEEA' }}
                fullWidth
                onClick={() => setImage(undefined)}>
                この画像を削除
            </Button>
        </DefaultLayout>
    )
}

export const RelativeGraveEditPicRelativeIdPage: FC = () => {
    return <RelativeGraveEditPicRelativeIdPageView />
}

export default RelativeGraveEditPicRelativeIdPage
