import { Image } from '@chakra-ui/react'
import { Avatar, Button, Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { type FC } from 'react'
import { useNavigate, useParams } from 'react-router'

import alert from '~/assets/image/alert.svg'
import tomb from '~/assets/image/icon/tomb.svg'
import add from '~/assets/image/personAdd.svg'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export type RelativeGraveSuccessfulPageViewProps = {}

export const RelativeGraveSuccessfulPageView: FC<RelativeGraveSuccessfulPageViewProps> = () => {
    const navigate = useNavigate()

    const { connectUuid } = useParams()

    const { queueDialog } = useConfirmationDialog()
    const apiClient = createApiClient()

    const onErrorHandle = async (e: unknown) => {
        let message = '取得に失敗しました'
        if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
        await queueDialog({
            type: 'alert',
            title: 'エラーが発生しました',
            text: message,
        })
    }

    const { data: connect } = useQuerySuspense(
        ['connect' + connectUuid],
        async () => {
            return await apiClient.clientConnectGetConnect({ parameter: { connectUuid: connectUuid ?? '' } })
        },
        { onError: onErrorHandle },
    )

    const onDelete = async () => {
        if (await queueDialog({ type: 'confirm', title: '削除', text: '削除します。よろしいでしょうか？' })) {
            return await apiClient
                .clientConnectDeleteRelative({ parameter: { connectUuid: connectUuid ?? '' } })
                .then(() => navigate('/user'))
                .catch(onErrorHandle)
        }
    }

    return (
        <DefaultLayout title={''} breadcrumbList={[]}>
            <Stack spacing={3} alignItems={'center'}>
                {connect?.isManageUser && (
                    <Stack spacing={1} py={'0.5rem'} px={'1rem'} bgcolor={'#EFEAE6'} width={'100%'}>
                        <Stack spacing={1} direction={'row'} alignItems={'center'}>
                            <Image src={alert} />
                            <Typography variant={'body2'}>お墓の管理依頼をするユーザーを設定してください</Typography>
                        </Stack>
                        <Button
                            onClick={() => navigate(`/connectList/${connect?.uuid}/relative/admin`)}
                            variant={'contained'}
                            startIcon={<img src={add} alt={'add'} />}>
                            お墓の管理依頼
                        </Button>
                    </Stack>
                )}
                <Stack spacing={2} alignItems={'center'}>
                    <Avatar
                        sx={{
                            borderWidth: '6px',
                            borderColor: '#AF985A',
                            borderStyle: 'solid',
                            objectFit: 'contain',
                            width: '8.125rem',
                            height: '10rem',
                        }}
                        alt={''}
                        src={mediaUrl(connect?.file, true)}
                        variant={'square'}
                    />
                    <Stack spacing={0.5} alignItems={'center'}>
                        <Stack spacing={0.5} direction={'row'}>
                            <Typography variant={'subtitle2'}>{connect?.name ?? ''}</Typography>
                            {connect?.relationship && (
                                <Typography variant={'subtitle2'}>{`(${connect?.relationship})`}</Typography>
                            )}
                        </Stack>
                        <Stack spacing={0.5} direction={'row'}>
                            <Typography variant={'body2'}>{connect?.sei ?? ''}</Typography>
                            <Typography variant={'body2'}>{connect?.mei ?? ''}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack spacing={2} width={'100%'} justifyContent={'center'}>
                    <Button
                        sx={{ height: '3rem' }}
                        onClick={() => navigate(`/connectList/${connect?.uuid}/grave`)}
                        variant={'contained'}
                        fullWidth
                        startIcon={<img alt="" src={tomb} />}>
                        お参り
                    </Button>
                    {connect?.isManageUser && (
                        <Button
                            sx={{ height: '3rem' }}
                            fullWidth
                            onClick={() =>
                                navigate(`/connectList/${connect?.uuid}/edit`, {
                                    state: { isDummy: connect?.isConnectUserDummy },
                                })
                            }
                            variant={'contained'}>
                            編集
                        </Button>
                    )}
                </Stack>

                {connect?.isManageUser && (
                    <Button fullWidth variant={'contained'} color="error" onClick={onDelete} sx={{ height: '3rem' }}>
                        このお墓を削除
                    </Button>
                )}
            </Stack>
        </DefaultLayout>
    )
}

export const RelativeGraveSuccessfulPage: FC = () => {
    return <RelativeGraveSuccessfulPageView />
}

export default RelativeGraveSuccessfulPage
