import { Box, Link, Stack, Typography } from '@mui/material'
import type { FC } from 'react'

import { Schemas } from '~/apis/types'
import trash from '~/assets/image/memory/trash.svg'
import { datetimeFormat } from '~/utils/common'

export type CCommentLeftProps = {
    comment: Schemas.ClientMemoryCommentListDto
    deleteMemoryCommentHandler: (commentUuid: string) => Promise<void>
    isDelete?: boolean
}

export const CCommentLeft: FC<CCommentLeftProps> = ({ comment, deleteMemoryCommentHandler, isDelete = false }) => {
    return (
        <Stack justifyContent={'start'} pr={6}>
            <Stack direction={'row'} justifyContent={'start'} spacing={1} sx={{ padding: '0 12px' }}>
                <Typography variant={'caption'} color={'rgba(60, 60, 67, 0.6)'}>
                    {comment.commentUserName}
                </Typography>
                <Typography variant={'caption'} color={'rgba(60, 60, 67, 0.6)'}>
                    {datetimeFormat(comment.createdAt)}
                </Typography>
            </Stack>
            <Box borderRadius={'18px'} bgcolor={'#E9E9EB'} sx={{ padding: '6px 12px' }}>
                <Typography variant={'body2'} sx={{ whiteSpace: 'pre-wrap' }}>
                    {comment.comment}
                </Typography>
            </Box>
            {isDelete && (
                <Stack direction={'row'} justifyContent={'start'} spacing={1}>
                    <Link
                        color={'#77618B'}
                        sx={{ textDecorationLine: 'underline', padding: '0 12px' }}
                        onClick={() => deleteMemoryCommentHandler(comment.uuid)}>
                        <img src={trash} style={{ position: 'relative', top: '4px', left: '-4px' }} />
                        削除
                    </Link>
                </Stack>
            )}
        </Stack>
    )
}
