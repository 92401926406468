import { Box, Stack, Typography } from '@mui/material'
import type { FC } from 'react'

import { Schemas } from '~/apis/types'
import moreHorizontal from '~/assets/image/memory/moreHorizontal.svg'
import { mediaUrl } from '~/utils/common'

export type CMemoryBaseInfoProps = {
    memory: Schemas.MemoryEntities
    menuButtonHandler?: (e: HTMLElement) => void
    imageMaster?: Schemas.ImageMasterEntities
}

export const CMemoryBaseInfo: FC<CMemoryBaseInfoProps> = ({ memory, menuButtonHandler, imageMaster }) => {
    return (
        <>
            <Stack direction={'column'} alignItems={'center'}>
                <img
                    alt={''}
                    src={memory.file ? mediaUrl(memory.file) : mediaUrl(imageMaster?.file)}
                    style={{ width: 216, height: 216, objectFit: 'cover', borderRadius: '10px' }}
                />
                {menuButtonHandler ? (
                    <>
                        <Stack alignItems={'end'} width={'100%'} sx={{ position: 'relative', top: 32, right: -8 }}>
                            <img
                                src={moreHorizontal}
                                onClick={(e) => menuButtonHandler(e.currentTarget)}
                                style={{ cursor: 'pointer' }}
                            />
                        </Stack>
                    </>
                ) : (
                    <></>
                )}
                <Box width={'sm'} pt={memory.sharedConnectUserName ? 2 : 'none'}>
                    {memory.sharedConnectUserName && (
                        <Typography variant={'caption'} sx={{ pt: 1, mx: 4, whiteSpace: 'pre-wrap' }}>
                            {memory.sharedConnectUserName} さんから
                        </Typography>
                    )}
                    <Typography sx={{ pt: 2, mx: 4, whiteSpace: 'pre-wrap' }} variant={'h6'}>
                        {memory.name}
                    </Typography>
                    <Typography sx={{ pt: 2, mx: 4, whiteSpace: 'pre-wrap' }} fontSize={'1rem'} textAlign={'center'}>
                        {`${memory.year}年` + (memory.month && `${memory.month}月`)}
                    </Typography>
                    <Typography sx={{ py: 2 }} variant="body2" width={'100%'}>
                        {memory.content}
                    </Typography>
                </Box>
            </Stack>
        </>
    )
}
