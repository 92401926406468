import { useDisclosure } from '@chakra-ui/hooks'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useState } from 'react'

import { Schemas } from '~/apis/types'
import { CConnectTagDialog } from '~/components/functional/cConnectTagDialog/CConnectTagDialog'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type QueryParameters = {
    page: number
    limit: number
}

export type UserConnectTagIndexPageProps = {}

export const useUserConnectTagIndexPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()

    const page = 1
    const limit = 10
    const [queryParams, setQueryParams] = useState<QueryParameters>({ page, limit })

    // initial fetch
    const { data: tagListResponse, refetch: fetchTagList } = useQuerySuspense(
        ['/connectTag', queryParams],
        async () => {
            const r = await apiClient.clientConnectTagGetList({ parameter: {} })
            return {
                list: r.list || [],
                count: r.count || 0,
            }
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    const handlePageChange = async (page: number) => {
        queryParams.page = page
        setQueryParams(queryParams)
        await fetchTagList()
    }

    // 編集モーダルの表示状態
    const [editTarget, setEditTarget] = useState<Schemas.ConnectTagEntities>()
    const { isOpen: editDialogIsOpen, onOpen: updateModalOnOpen, onClose: editDialogOnClose } = useDisclosure()

    const addButtonHandler = () => {
        setEditTarget(undefined)
        updateModalOnOpen()
    }

    const editButtonHandler = (tag: Schemas.ConnectTagEntities) => {
        setEditTarget(tag)
        updateModalOnOpen()
    }

    const editDialogSubmitHandler = async (dto: Schemas.ClientConnectTagCreateDto, uuid?: string) => {
        try {
            if (!uuid) await apiClient.clientConnectTagCreate({ requestBody: dto })
            else await apiClient.clientConnectTagUpdate({ requestBody: dto, parameter: { tagUuid: uuid! } })
            await fetchTagList()
            editDialogOnClose()
        } catch (e) {
            let message = '登録・更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }
    const deleteHandler = async (tag: Schemas.ConnectTagEntities) => {
        try {
            const message = '削除されたラベルは復元できません。'
            if (!tag) return
            if (
                await queueDialog({
                    type: 'confirm',
                    title: 'ラベルを削除しますか？',
                    text: message,
                })
            ) {
                await apiClient.clientConnectTagDelete({ parameter: { tagUuid: tag.uuid } })
                await fetchTagList()
            }
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const EditDialog = (
        <CConnectTagDialog
            isOpen={editDialogIsOpen}
            onClose={editDialogOnClose}
            onSubmit={editDialogSubmitHandler}
            entity={editTarget}
        />
    )

    return {
        list: tagListResponse?.list || [],
        count: tagListResponse?.count || 0,
        queryParams,
        handlePageChange,

        addButtonHandler,
        editButtonHandler,
        deleteHandler,
        EditDialog,
    }
}

export const UserConnectTagIndexPage: FC<UserConnectTagIndexPageProps> = () => {
    const {
        list,

        addButtonHandler,
        editButtonHandler,
        deleteHandler,
        EditDialog,
    } = useUserConnectTagIndexPage()
    return (
        <>
            <DefaultLayout title={''} breadcrumbList={[]}>
                <Grid container direction="row" justifyContent="end" alignItems="center" sx={{ my: 2 }}>
                    <Grid item />
                </Grid>
                <Stack spacing={2}>
                    <Box borderRadius={'8px'} padding={2} sx={{ backgroundColor: '#EFEAE6' }}>
                        {list.map((tag: Schemas.ConnectTagEntities, index: number) => (
                            <Stack component={'div'} key={tag.uuid} spacing={2}>
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ pt: 2 }}>
                                    <Typography variant={'body2'}>{tag.name}</Typography>
                                    <Stack direction="row" spacing={2} sx={{ height: '36px' }}>
                                        <Button
                                            variant="text"
                                            color="error"
                                            onClick={() => deleteHandler(tag)}
                                            startIcon={<RemoveCircleIcon />}>
                                            削除
                                        </Button>
                                        <LoadingButton variant="contained" onClick={() => editButtonHandler(tag)} sx={{ p: 0 }}>
                                            編集
                                        </LoadingButton>
                                    </Stack>
                                </Stack>
                                {index < list.length - 1 && <Divider />}
                            </Stack>
                        ))}
                    </Box>
                    <Button variant="contained" color="secondary" onClick={addButtonHandler}>
                        ラベルを新規登録
                    </Button>
                </Stack>
            </DefaultLayout>
            {EditDialog}
        </>
    )
}
